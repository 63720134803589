import axios from 'axios';

export default class Alert {
	list(filter = { all: false }) {
		const url = '/api/alerts' + (filter.all ? '?all=true' : '');
		return new Promise((resolve, reject) => {
			axios
				.get(url)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	add(alert) {
		return new Promise((resolve, reject) => {
			axios
				.post('/api/alerts', alert)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	edit(alert) {
		return new Promise((resolve, reject) => {
			axios
				.post('/api/alerts/' + alert.id, alert)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	remove(id) {
		return new Promise((resolve, reject) => {
			axios
				.delete('/api/alerts/' + id)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}
}
