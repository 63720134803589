import axios from 'axios';

export default class Asset {
	list() {
		return new Promise((resolve, reject) => {
			axios
				.get('/api/assets')
				.then(response => {
					const data = response.data;
					data.spacecrafts = this._mapSpacecrafts(data);
					resolve(data);
				})
				.catch(ex => reject(ex.response));
		});
	}

	_mapSpacecrafts(data) {
		return data.spacecrafts.map(spacecraft => ({
			id: spacecraft.id,
			name: spacecraft.redact_name || spacecraft.name,
			norad_id: spacecraft.norad_id,
			custom_tle: spacecraft.twoline_enabled,
			lifecycle_state: spacecraft.lifecycle_state,
			active: null,
			activeLocation: '',
			next: null,
			countdown: '',
			contacts: []
		}));
	}
}
