
const settings = [
	{
		metric: 'antenna1_position_azimuth',
		stepSize: 45,
		suggestedMax: 360,
		beginAtZero: true
	},
	{
		metric: 'antenna1_position_elevation',
		stepSize: 15,
		suggestedMax: 90,
		beginAtZero: true
	},
	{
		metric: 'antenna1_hpa_forwardRfPower',
		suggestedMax: 50
	},
	{
		metric: 'lr1_commandsSent',
		beginAtZero: true
	},
	{
		metric: 'lr1_commandsDiscarded',
		beginAtZero: true
	}
];

export {
	settings
};
