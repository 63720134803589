import axios from 'axios';

export default class Organization {
	list() {
		return new Promise((resolve, reject) => axios
			.get('/api/organizations')
			.then(response => resolve(response.data))
			.catch(ex => reject(ex.response))
		);
	}
}
