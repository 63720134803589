import { cloneDeep } from 'lodash';

export const schemaBase = (schema) => {
	return [
		'$schema',
		'definitions'
	].reduce((base, property) => {
		if (typeof schema[property] === 'undefined') {
			return base;
		}

		return {
			...base,
			[property]: cloneDeep(schema[property])
		};
	}, {});
};
