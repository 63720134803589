import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import Notifications from '@kyvg/vue3-notification';
import velocity from 'velocity-animate';
import { vTooltip } from 'floating-vue';
import { Icon } from 'leaflet';
import EventBus from './assets/plugins/EventBus';
import EventHandler from '@/assets/plugins/EventHandler';
import EnvironmentHandler, { isFeatureEnabled, environmentHandlerInitiated } from '@/assets/plugins/EnvironmentHandler';
import AuthHandler, { isAuthenticated, hasCustomerAdminAccess } from '@/assets/plugins/AuthHandler';
import Monitoring, { monitorBefore, monitorError } from '@/assets/plugins/Monitoring';
import { DateTime } from 'luxon';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import VueClickAway from "vue3-click-away";

// Global components
import VBox from '@/components/VBox';
import VModal from '@/components/VModal';
import VAutoResizeTextarea from '@/components/VAutoResizeTextarea';
import VTagsInput from '@/components/VTagsInput';
import VTabs from '@/components/VTabs';
import VDateTimePicker from '@/components/VDateTimePicker';
import VDropdown from '@/components/VDropdown';

// Icons - we should only add icons we are using, and never entire themes
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import icons from './icons';

import 'floating-vue/dist/style.css';
import 'leaflet/dist/leaflet.css';
import 'vue-leaflet-markercluster/dist/style.css';

library.add(icons);

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

router.beforeEach(async (to, _, next) => {
	if (isAuthenticated()) {
		await environmentHandlerInitiated;

		if (to.name === 'admin' && !hasCustomerAdminAccess()) {
			next({ path: '/404' });
		} else if (to.meta.requiresFeature && !isFeatureEnabled(to.meta.requiresFeature)) {
			next({ path: '/404' });
		} else {
			document.title = to.meta.title + ' - KSAT';
			monitorBefore(to);
			next();
		}
	} else if (to.meta.noAuth) {
		document.title = to.meta.title + ' - KSAT';
		monitorBefore(to);
		next();
	} else {
		const redirectTo = {
			name: 'login',
			path: to.name === 'dashboard' || !to.name ? '/login' : '/login?redirect_to=' + to.path
		};
		monitorBefore(redirectTo);
		next({ path: redirectTo.path });
	}
});

const mixins = {
	methods: {
		showLoading() {
			this.$root.isLoading = true;
			this.$root.loadStarted = Date.now();
		},
		hideLoading(minimumWait = 0) {
			const root = this.$root;
			const duration = Date.now() - root.loadStarted;
			if (duration < minimumWait) {
				setTimeout(() => {
					root.isLoading = false;
				}, minimumWait - duration);
			} else {
				root.isLoading = false;
			}
		},
		showNotification(text, type) {
			this.$notify({
				group: 'main',
				text: text,
				type: type || 'warn',
				ignoreDuplicates: true
			});
		},
		setComma(items) {
			return items.join(', ');
		},
		find(list, id, keyword = 'name') {
			const item = list.find((element) => element.id === id);
			return item && item[keyword] ? item[keyword] : '';
		},
		download(uri, filename) {
			const url = encodeURI(uri);
			const a = document.createElement('a');

			a.href = url;
			a.download = filename;
			a.click();
		},
		prettifyDate(date) {
			return DateTime.fromISO(date).toUTC()
				.toFormat('dd.LL.yyyy HH:mm');
		},
		handleError(dataType, error) {
			console.log(error);
			monitorError(error, dataType);
			this.showNotification(
				'An error occurred during loading of ' + dataType,
				'error'
			);
		},
		handleNotFound(dataType, error) {
			console.log(error);
			this.showNotification('Unable to find ' + dataType, 'warn');
		},
		contactCountdown(duration) {
			if (duration.days) {
				return '-' + duration.toFormat("d 'days' hh:mm:ss");
			}
			return '-' + duration.toFormat('hh:mm:ss');
		}
	}
};

const app = createApp(App)
	.component('VBox', VBox)
	.component('VModal', VModal)
	.component('VAutoResizeTextarea', VAutoResizeTextarea)
	.component('VTagsInput', VTagsInput)
	.component('VTabs', VTabs)
	.component('VDateTimePicker', VDateTimePicker)
	.component('VDropdown', VDropdown)
	.component('fa-icon', FontAwesomeIcon)
	.use(router)
	.use(store)
	.use(EventBus)
	.use(EventHandler)
	.use(EnvironmentHandler)
	.use(Monitoring, {
		setupPageTracking: false,
		router
	})
	.use(AuthHandler)
	.use(VueDOMPurifyHTML)
	.use(Notifications, { velocity })
	.use(VueClickAway)
	.directive('tooltip', vTooltip)
	.directive('moveElement', {
		inserted(element, options) {
			const container = options.arg
				? document.getElementById(options.arg)
				: document.body;

			if (options.modifiers.prepend && container.firstChild) {
				container.insertBefore(element, container.firstChild);
			} else {
				container.appendChild(element);
			}
		},
		unbind(element) {
			if (element.parentNode) {
				element.parentNode.removeChild(element);
			}
		}
	})
	.mixin(mixins);

app.mount('#app');
