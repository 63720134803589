const defaultState = () => {
	return {
		tracking: {
			HPA_forward_RF_power: {},
			az_position: {},
			el_position: {}
		},
		uplink: {
			commandsSent: {},
			commandsReceived: {},
			commandsDiscarded: {},
			commandQueueDepth: {},
			modCarrierFrequency: {},
			modModulationType: {},
			modPcmEncoder1CodingType: {}
		},
		's-band': {
			bitRate: {},
			carrierFrequency: {},
			carrierLockState: {},
			modulationType: {},
			doppler: {},
			measuredEbN0: {},
			pcmDecoder1CodingType: {},
			viterbi1LockState: {},
			symbolLockState: {}
		},
		'x/ka-band': {
			modulation: {},
			bitRateI: {},
			carrierLock: {},
			esnoI: {},
			symbolLockI: {},
			symbolRate: {},
			frameSyncLockState: {},
			totalBitRate: {},
			viterbiILockState: {},
			viterbiQLockState: {}
		}
	};
};

export {
	defaultState
};
