export default (...properties) => {
	// Remove the last argument, it should always be the options
	const options = properties.pop();
	const getOption = (option, fallback) => {
		return options[option] ?? fallback;
	};

	const prefix = options.prefix === true ? options.to : getOption('prefix', '');
	const suffix = getOption('suffix', '');

	// The remaining arguments are names of properties to delegate to options.to
	return properties.reduce((methods, property) => {
		// The name of the property is created with the prefix and suffix options,
		// making sure the resulting property name is camelCased.
		const propertyName = [
			prefix,
			property,
			suffix
		].reduce((name, segment) => {
			if (name.length === 0) {
				return segment;
			} else if (segment.length === 0) {
				return name;
			}

			return name + segment.at(0).toUpperCase() + segment.slice(1);
		}, '');

		return {
			...methods,
			[propertyName]() {
				if (typeof this[options.to] === 'undefined' || this[options.to] === null) {
					console.warn(`delegate: this.${options.to} is not present`);
					return null;
				}
				return this[options.to][property];
			}
		};
	}, {});
};
