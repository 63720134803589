import { DateTime, Interval } from 'luxon';

export default class ContactFilter {
	constructor() {
		this.from = DateTime.utc();
		this.to = DateTime.utc();
		this.duration = '';
		this.spacecrafts = [];
		this.systems = [];
		this.stations = [];
		this.sortDescending = false;

		this.minFrom = DateTime.utc().minus({ days: 7 })
			.set({ hour: 0, minute: 0, second: 0, milliseconds: 0 });
		this.maxTo = DateTime.utc().plus({ days: 15 })
			.set({ hour: 0, minute: 0, second: 0, milliseconds: 0 });

		this.resetTime();
	}

	set(filter) {
		if (typeof filter.from === 'string') {
			this.from = DateTime.fromISO(filter.from).toUTC();
			this.to = DateTime.fromISO(filter.to).toUTC();
		} else {
			this.from = filter.from;
			this.to = filter.to;
		}

		this.duration = filter.duration;
		this.spacecrafts = filter.spacecrafts;
		this.systems = filter.systems;
		this.stations = filter.stations;
		this.sortDescending = filter.sortDescending;
	}

	reset() {
		this.resetTime();
		this.duration = '';
		this.spacecrafts = [];
		this.systems = [];
		this.stations = [];
	}

	resetTime() {
		this.resetFromTime();
		this.resetToTime();
	}

	resetFromTime() {
		this.from = DateTime.utc().minus({ hours: 4 });
	}

	resetToTime() {
		this.to = DateTime.utc().plus({ hours: 24 });
	}

	setMinFrom() {
		this.from = DateTime.fromISO(this.minFrom.toISO()).toUTC();
	}

	setMaxTo() {
		this.to = DateTime.fromISO(this.maxTo.toISO()).toUTC();
	}

	isWithinTimespan(timespan) {
		return this.from >= timespan.from && this.to <= timespan.to;
	}

	durationInHours() {
		return Interval.fromDateTimes(this.from, this.to).length('hours');
	}

	updateContactVisibility(contacts) {
		const duration = +this.duration;
		const spacecrafts = this.spacecrafts.map(item => item.text.toLowerCase());
		const systems = this.systems.map(item => item.text.toLowerCase());
		const stations = this.stations.map(item => item.text.toLowerCase());

		contacts.forEach(contact => {
			if (contact.from < this.from || contact.to > this.to) {
				contact.show = false;
			} else {
				const spacecraftMatch = !spacecrafts.length || spacecrafts.includes(contact.name.toLowerCase());
				const systemMatch = !systems.length || systems.includes(contact.system.toLowerCase());
				const stationMatch = !stations.length || stations.includes(contact.station.toLowerCase());

				contact.show = contact.duration.shiftTo('minutes').minutes >= duration &&
					spacecraftMatch && systemMatch && stationMatch;
			}
		});
	}
}
