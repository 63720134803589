import {
	isArray,
	isObject,
	isString
} from 'lodash';

import { extractDefinitions, getAllProperties } from './properties';
import { hoistProperty } from './hoist-property';
import { expandRef } from './ref';

const isMeasurement = (object) => {
	return Object.keys(object).every((property) => {
		return ['unit', 'value'].includes(property);
	});
};

const getPrunableProperties = (schema) => {
	const allProperties = getAllProperties(schema);
	const requiredProperties = schema.required ?? [];

	// If the number of properties is the same as minProperties, everything is
	// required
	if (allProperties.length === schema.minProperties) {
		return [];
	}

	// Find all properties not in schema.required
	return allProperties
		.map(({ id }) => {
			return id;
		})
		.filter((id) => {
			return !requiredProperties.includes(id);
		});
};

const isBlank = (value) => {
	if (isArray(value)) {
		return value.length === 0 || value.every(isBlank);
	} else if (isString(value)) {
		return value.length === 0;
	} else if (isObject(value)) {
		if (isMeasurement(value)) {
			// always prune key unit since we try to autofill in the FORM if there is only one to select from.
			return isBlank(value?.value);
		}
		return Object.values(value).every(isBlank);
	}

	return value === null || typeof value === 'undefined';
};

const extractItemSchema = (schema, items) => {
	const base = expandRef(schema, items);

	return {
		...base,
		definitions: extractDefinitions(schema, base)
	};
};

export const pruneOptionalProperties = (schema, object) => {
	if (schema.type === 'array') {
		const itemSchema = extractItemSchema(schema, schema.items);

		return object.map((arrayItem) => {
			return pruneOptionalProperties(
				itemSchema,
				arrayItem
			);
		});
	} else if (schema.type !== 'object') {
		return object;
	}

	const prunableProperties = getPrunableProperties(schema);

	return Object
		.entries(object)
		.reduce((object, [property, value]) => {
			if (prunableProperties.includes(property) && isBlank(value)) {
				return object;
			}

			return {
				...object,
				[property]: pruneOptionalProperties(
					hoistProperty(schema, property),
					value
				)
			};
		}, {});
};
