import axios from 'axios';
import { Interval } from 'luxon';

const baseURL = '/api/unavailabilities';
export default class Unavailabilities {
	list(from, to) {
		return new Promise((resolve, reject) => axios
			.get(baseURL, { params: { from, to } })
			.then(response => resolve(response.data.map(unavailability => ({
				...unavailability, timeInterval: Interval.fromISO(`${unavailability.start_time}/${unavailability.end_time}`)
			}))))
			.catch(ex => reject(ex.response))
		);
	}
}
