import {
	isArray,
	isDateInterval,
	isDateTime,
	isFunction,
	isObject,
	isString,
	toDateInterval,
	toDateTime
} from './types';

const predicates = {
	/**
	 * Test if two objects are equal. The way equality is decided depends on the
	 * type of `expected`:
	 *
	 * - **arrays** require two arrays to have the same number of elements and
	 *   that all elements at the same index to be equal according to this
	 *   function.
	 * - **dates** are conventerted to `Luxon.DateTime` and compared using
	 *   `equals`.
	 * - **objects** require the same keys and the value of each to be equal
	 *   according to this function.
	 * - anything else is compared using `===`.
	 *
	 * @return {function}
	 */
	eq(expected) {
		if (isArray(expected)) {
			expected = expected.map((e) => predicates.eq(e));

			return (actual) => {
				if (!isArray(actual)) {
					return false;
				} else if (expected.length !== actual.length) {
					return false;
				}

				return expected.every((matches, i) => {
					return matches(actual[i]);
				});
			};
		} else if (isDateTime(expected)) {
			expected = toDateTime(expected);

			return (actual) => {
				return isDateTime(actual) && expected.equals(toDateTime(actual));
			};
		} else if (isObject(expected)) {
			const hasSameKeys = predicates.eq(Object.keys(expected));
			expected = Object.entries(expected).reduce((methods, [key, value]) => {
				return {
					...methods,
					[key]: predicates.eq(value)
				};
			}, {});
			return (actual) => {
				if (!isObject(actual)) {
					return false;
				} else if (!hasSameKeys(Object.keys(actual))) {
					return false;
				}

				return Object.entries(expected).every(([key, predicate]) => {
					return predicate(actual[key]);
				});
			};
		}

		return (actual) => {
			return actual === expected;
		};
	},
	/**
	 * Inverse of {@link #eq|eq}
	 *
	 * @return {function}
	 */
	not(expected) {
		const eq = predicates.eq(expected);

		return (actual) => {
			return !eq(actual);
		};
	},
	/**
	 * Test if predicate arguments start with expected. Only works with strings.
	 *
	 * @return {function}
	 */
	startsWith(expected) {
		return (actual) => {
			if (isString(actual)) {
				return actual.startsWith(expected);
			}

			return false;
		};
	},
	/**
	 * Test if the predicate's argument is greater than the factory method's
	 * argument. `expected`'s type decides the behaviour:
	 *
	 * - **date's** are conventerted to `Luxon.DateTime` and compared using `>`.
	 * - anything else is compared using `>` directly.
	 *
	 * @return {function}
	 */
	gt(expected) {
		if (isDateTime(expected)) {
			expected = toDateTime(expected);

			return (actual) => {
				return toDateTime(actual) > expected;
			};
		}
		return (actual) => {
			return actual > expected;
		};
	},
	/**
	 * Test if the predicate's argument is greater than or equal to the factory
	 * method's argument. `expected`'s type decides the behaviour:
	 *
	 * - **date's** are conventerted to `Luxon.DateTime` and compared using `>=`.
	 * - anything else is compared using `>=` directly.
	 *
	 * @return {function}
	 */
	gte(expected) {
		if (isDateTime(expected)) {
			expected = toDateTime(expected);

			return (actual) => {
				return toDateTime(actual) >= expected;
			};
		}
		return (actual) => {
			return actual >= expected;
		};
	},
	/**
	 * Test if the predicate's argument is less than the factory method's
	 * argument. `expected`'s type decides the behaviour:
	 *
	 * - **date's** are conventerted to `Luxon.DateTime` and compared using `<=`.
	 * - anything else is compared using `<=` directly.
	 *
	 * @return {function}
	 */
	lt(expected) {
		if (isDateTime(expected)) {
			expected = toDateTime(expected);

			return (actual) => {
				return toDateTime(actual) < expected;
			};
		}
		return (actual) => {
			return actual < expected;
		};
	},
	/**
	 * Test if the predicate's argument is less than or equal to the factory
	 * method's argument. `expected`'s type decides the behaviour:
	 *
	 * - **date's** are conventerted to `Luxon.DateTime` and compared using `<=`.
	 * - anything else is compared using `<=` directly.
	 *
	 * @return {function}
	 */
	lte(expected) {
		if (isDateTime(expected)) {
			expected = toDateTime(expected);

			return (actual) => {
				return toDateTime(actual) <= expected;
			};
		}
		return (actual) => {
			return actual <= expected;
		};
	},
	/**
	 * Test if the predicate's argument(s) is between the factory methods
	 * argument(s).
	 *
	 * `expected`'s type decides the behaviour:
	 * - **date interval's** uses `Luxon.Interval` and compared using `contains`.
	 * - anything else requires both {@link #gte|gte} and {@link #lte|lte} to be
	 *   false.
	 *
	 * @return {function}
	 */
	between(value) {
		if (isDateInterval(value)) {
			value = toDateInterval(value);

			return (actual) => {
				return value.contains(toDateTime(actual));
			};
		}

		const [min, max] = value;
		const gte = predicates.gte(min);
		const lte = predicates.lte(max);
		return (actual) => {
			return gte(actual) && lte(actual);
		};
	},
	/**
	 * Test if the predicate range overlaps the factory method range.
	 *
	 * `expected`'s type decides the behaviour:
	 * - **date interval's** uses `Luxon.Interval` and compared using `overlaps`.
	 * - anything else does nothing
	 *
	 * @return {function}
	 */
	overlaps(value) {
		if (isDateInterval(value)) {
			value = toDateInterval(value);

			return (actual) => {
				return toDateInterval(actual).overlaps(value);
			};
		}

		return () => {
			console.warn('overlaps is not yet implemented for type');
			return false;
		};
	},
	/**
	 * Test if the predicate's argument is an array and contain the factory
	 * methods argument. Comparison is done using {@link #eq|eq}.
	 *
	 * @return {function}
	 */
	includes(value) {
		return (array) => {
			if (!isArray(array)) {
				return false;
			}

			const isExpectedValue = predicates.eq(value);
			return array.some(isExpectedValue);
		};
	},
	/**
	 * Same as {@link #includes|includes}, but with the arguments flipped.
	 *
	 * @return {function}
	 */
	in(array) {
		return (value) => {
			return predicates.includes(value)(array);
		};
	},
	/**
	 * Opposite of {@link #in|in}.
	 *
	 * @return {function}
	 */
	notIn(array) {
		const predicate = predicates.in(array);

		return (value) => {
			return !predicate(value);
		};
	},
	/**
	 * Test if the value is present or not.
	 *
	 * To be present, a value must not:
	 * - be undefined
	 * - be null
	 *
	 * @return {function}
	 */
	present(presence = true) {
		const isPresent = (value) => {
			if (
				typeof value === 'undefined' ||
				value === null
			) {
				return false;
			}

			return true;
		};

		if (!presence) {
			return (...args) => {
				return !isPresent(...args);
			};
		}

		return isPresent;
	}
};

export default predicates;

export const isPredicate = (value) => {
	return isFunction(value);
};

export const isPredicateDefinition = (value) => {
	if (typeof value !== 'object') {
		return false;
	}
	if (Object.keys(value).length !== 1) {
		return false;
	}

	return Object.keys(predicates).includes(Object.keys(value)[0]);
};
