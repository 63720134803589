
const lowrateUpDownHighrateDown = {
	name: 'LR up/down, HR down',
	columns: [
		{
			title: 'Spacecraft',
			chartMetrics: [
				'antenna1_position_azimuth',
				'antenna1_position_elevation'
			],
			tableMetrics: [
				'antenna1_position_azimuth',
				'antenna1_position_elevation'
			]
		},
		{
			title: 'Low rate uplink',
			chartMetrics: [
				'lr1_commandsSent',
				'antenna1_hpa_forwardRfPower',
				'lr1_commandsDiscarded'
			],
			tableMetrics: [
				'lr1_commandsSent',
				'antenna1_hpa_forwardRfPower',
				'lr1_commandsDiscarded',
				'lr1_modulator_modModulationType',
				'lr1_receiver1_carrierFrequency',
				'lr1_commandsReceived',
				'lr1_commandQueueDepth',
				'lr1_modulator_modPcmEncoder1CodingType'
			]
		},
		{
			title: 'Low rate downlink',
			chartMetrics: [
				'lr1_receiver1_ebN0',
				'digitizer1_inputRfPort1_power'
			],
			tableMetrics: [
				'lr1_receiver1_ebN0',
				'digitizer1_inputRfPort1_power',
				'lr1_receiver1_bitRate',
				'lr1_receiver1_modulationType',
				'lr1_receiver1_carrierFrequency',
				'lr1_receiver1_carrierLockState',
				'lr1_receiver1_doppler',
				'lr1_receiver1_pcmDecoderCodingType',
				'lr1_receiver1_viterbiLockState',
				'lr1_receiver1_symbolLockState'
			]
		},
		{
			title: 'High rate downlink',
			chartMetrics: [
				'hr1_receiver1_esN0',
				'hr1_decoder1_totalBlockErrorRate'
			],
			tableMetrics: [
				'hr1_receiver1_esN0',
				'hr1_decoder1_totalBlockErrorRate',
				'hr1_receiver1_bitRate',
				'hr1_receiver1_modulationType',
				'hr1_frameSync1_bitRate',
				'hr1_receiver1_carrierLockState',
				'hr1_receiver1_symbolLockState',
				'hr1_receiver1_symbolRate',
				'hr1_frameSync1_lockState',
				'hr1_receiver1_viterbiILockState',
				'hr1_receiver1_viterbiQLockState'
			]
		}
	]
};

const lowrateUpDown = {
	name: 'LR up/down',
	columns: [
		{
			title: 'Spacecraft',
			chartMetrics: [
				'antenna1_position_azimuth',
				'antenna1_position_elevation'
			],
			tableMetrics: [
				'antenna1_position_azimuth',
				'antenna1_position_elevation'
			]
		},
		{
			title: 'Low rate uplink',
			chartMetrics: [
				'lr1_commandsSent',
				'antenna1_hpa_forwardRfPower',
				'lr1_commandsDiscarded'
			],
			tableMetrics: [
				'lr1_commandsSent',
				'antenna1_hpa_forwardRfPower',
				'lr1_commandsDiscarded',
				'lr1_modulator_modModulationType',
				'lr1_receiver1_carrierFrequency',
				'lr1_commandsReceived',
				'lr1_commandQueueDepth',
				'lr1_modulator_modPcmEncoder1CodingType'
			]
		},
		{
			title: 'Low rate downlink',
			chartMetrics: [
				'lr1_receiver1_ebN0',
				'digitizer1_inputRfPort1_power'
			],
			tableMetrics: [
				'lr1_receiver1_ebN0',
				'digitizer1_inputRfPort1_power',
				'lr1_receiver1_bitRate',
				'lr1_receiver1_modulationType',
				'lr1_receiver1_carrierFrequency',
				'lr1_receiver1_carrierLockState',
				'lr1_receiver1_doppler',
				'lr1_receiver1_pcmDecoderCodingType',
				'lr1_receiver1_viterbiLockState',
				'lr1_receiver1_symbolLockState'
			]
		}
	]
};

const lowrateDown = {
	name: 'LR down',
	columns: [
		{
			title: 'Spacecraft',
			chartMetrics: [
				'antenna1_position_azimuth',
				'antenna1_position_elevation'
			],
			tableMetrics: [
				'antenna1_position_azimuth',
				'antenna1_position_elevation'
			]
		},
		{
			title: 'Low rate downlink',
			chartMetrics: [
				'lr1_receiver1_ebN0',
				'digitizer1_inputRfPort1_power'
			],
			tableMetrics: [
				'lr1_receiver1_ebN0',
				'digitizer1_inputRfPort1_power',
				'lr1_receiver1_bitRate',
				'lr1_receiver1_modulationType',
				'lr1_receiver1_carrierFrequency',
				'lr1_receiver1_carrierLockState',
				'lr1_receiver1_doppler',
				'lr1_receiver1_pcmDecoderCodingType',
				'lr1_receiver1_viterbiLockState',
				'lr1_receiver1_symbolLockState'
			]
		}
	]
};

const lowrateUpHighrateDown = {
	name: 'LR up, HR down',
	columns: [
		{
			title: 'Spacecraft',
			chartMetrics: [
				'antenna1_position_azimuth',
				'antenna1_position_elevation'
			],
			tableMetrics: [
				'antenna1_position_azimuth',
				'antenna1_position_elevation'
			]
		},
		{
			title: 'Low rate uplink',
			chartMetrics: [
				'lr1_commandsSent',
				'antenna1_hpa_forwardRfPower',
				'lr1_commandsDiscarded'
			],
			tableMetrics: [
				'lr1_commandsSent',
				'antenna1_hpa_forwardRfPower',
				'lr1_commandsDiscarded',
				'lr1_modulator_modModulationType',
				'lr1_receiver1_carrierFrequency',
				'lr1_commandsReceived',
				'lr1_commandQueueDepth',
				'lr1_modulator_modPcmEncoder1CodingType'
			]
		},
		{
			title: 'High rate downlink',
			chartMetrics: [
				'hr1_receiver1_esN0',
				'hr1_decoder1_totalBlockErrorRate'
			],
			tableMetrics: [
				'hr1_receiver1_esN0',
				'hr1_decoder1_totalBlockErrorRate',
				'hr1_receiver1_bitRate',
				'hr1_receiver1_modulationType',
				'hr1_frameSync1_bitRate',
				'hr1_receiver1_carrierLockState',
				'hr1_receiver1_symbolLockState',
				'hr1_receiver1_symbolRate',
				'hr1_frameSync1_lockState',
				'hr1_receiver1_viterbiILockState',
				'hr1_receiver1_viterbiQLockState'
			]
		}
	]
};

const presets = [
	lowrateUpDownHighrateDown,
	lowrateUpHighrateDown,
	lowrateUpDown,
	lowrateDown
];

const defaultPreset = lowrateUpDownHighrateDown;

export {
	defaultPreset,
	presets
};
