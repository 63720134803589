import axios from 'axios';

export default class Tenant {
	list() {
		return new Promise((resolve, reject) => axios
			.get('/api/tenants')
			.then(response => resolve(response.data))
			.catch(ex => reject(ex.response))
		);
	}

	all() {
		return new Promise((resolve, reject) => axios
			.get('/api/alltenants')
			.then(response => resolve(response.data))
			.catch(ex => reject(ex.response))
		);
	}
}
