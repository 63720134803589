import { cloneDeep, partial, isArray, isObject, isString } from 'lodash';

import dig from '@/assets/utils/dig';
import { createFilter } from '@/assets/utils/filter';

import { expandRef, isRef, getRef } from './ref';

export const getAllProperties = (schema, object = {}) => {
	return [
		...getProperties(schema, object),
		...getEnumProperties(schema, object),
		...getPatternProperties(schema, object),
		...getAdditionalProperties(schema, object),
		...expandAllOf(schema, object)
	].filter(({ id }, i, properties) => {
		return properties.findIndex(createFilter({ id })) === i;
	});
};

export const getOptionalPropertyNames = (schema) => {
	if (!isObject(schema.propertyNames)) {
		return [];
	}
	if (schema.propertyNames.type !== 'string' || !isArray(schema.propertyNames.enum)) {
		console.warn('getOptionalPropertyNames: Unexpected propertyNames encountered', schema.propertyNames);
		return [];
	}

	return schema.propertyNames.enum;
};

export const getProperties = (schema, object = {}) => {
	if (!isObject(schema.properties)) {
		return [];
	}

	return Object.keys(schema.properties)
		.map((id) => {
			return toProperty(schema, object, id);
		});
};

export const getEnumProperties = (schema, object = {}) => {
	if (
		schema.propertyNames?.type !== 'string' ||
		!isArray(schema.propertyNames?.enum)
	) {
		return [];
	}

	return schema.propertyNames.enum.reduce((properties, propertyName) => {
		if (!Object.keys(object).includes(propertyName)) {
			return properties;
		}

		return [
			...properties,
			toProperty(schema, object, propertyName, schema.additionalProperties)
		];
	}, []);
};

export const getPatternProperties = (schema, object = {}) => {
	if (!isObject(schema.patternProperties)) {
		return [];
	}

	return Object
		.entries(schema.patternProperties)
		.reduce((properties, [pattern, subschema]) => {
			pattern = new RegExp(pattern);

			Object.keys(object).forEach((property) => {
				if (!pattern.test(property)) {
					return;
				}

				properties.push(toProperty(schema, object, property, subschema));
			});

			return properties;
		}, []);
};

export const getAdditionalProperties = (schema, object = {}) => {
	if (!isObject(schema.additionalProperties)) {
		return [];
	}

	const usedPropertyNames = [
		...getProperties(schema).map(({ id }) => id),
		...getPatternProperties(schema, object).map(({ id }) => id),
		...expandAllOf(schema, object).map(({ id }) => id)
	];
	const optionalPropertyNames = getOptionalPropertyNames(schema);
	const additionalPropertyNames = Object.keys(object).filter((property) => {
		return !usedPropertyNames.includes(property) &&
			optionalPropertyNames.includes(property);
	});

	return additionalPropertyNames.map((property) => {
		return toProperty(schema, object, property, schema.additionalProperties);
	});
};

export const expandAllOf = (schema, object = {}) => {
	if (!isArray(schema.allOf)) {
		return [];
	}

	return schema.allOf
		.filter((item) => {
			if (!isRef(item)) {
				console.warn('expandAllOf encountered non-ref item');
				return false;
			}

			return true;
		})
		.map(partial(expandRef, schema))
		.flatMap((entry) => {
			if (!Object.keys(entry).includes('properties')) {
				console.warn('expandAllOf: Unexpected entry encountered', entry);
				return [];
			}

			return Object.entries(entry.properties).map(([property, subschema]) => {
				return toProperty(schema, object, property, subschema);
			});
		});
};

const toProperty = (schema, object, id, subschema = null) => {
	subschema = subschema ?? dig(schema, ['properties', id]);
	subschema = expandRef(schema, subschema);

	return {
		id,
		subschema: {
			...subschema,
			definitions: extractDefinitions(schema, subschema)
		}
	};
};

export const extractDefinitions = (schema, subschema) => {
	return findRefs(schema, subschema)
		.map(getRef)
		.reduce((definitions, ref) => {
			const definition = cloneDeep(dig(schema, ['definitions', ref]));

			return {
				...definitions,
				[ref]: definition
			};
		}, {});
};

const findRefs = (schema, subschema) => {
	if (!isObject(subschema)) {
		return [];
	}

	return Object
		.entries(subschema)
		.reduce((refs, [key, value]) => {
			if (key === '$ref') {
				return refs.concat(
					value,
					findRefs(schema, dig(schema, ['definitions', getRef(value)]))
				);
			} else if (isArray(value)) {
				return refs.concat(value.flatMap((item) => {
					return findRefs(schema, item);
				}));
			} else if (isObject(value)) {
				return refs.concat(findRefs(schema, value));
			}

			return refs;
		}, [])
		.filter((ref, i, refs) => {
			return isString(ref) &&
				refs.indexOf(ref) === i;
		});
};
