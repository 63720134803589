export const Severities = [
	{ id: 'low', name: 'low' },
	{ id: 'medium', name: 'medium' },
	{ id: 'high', name: 'high' }
];

export const UserRoles = [
	{ id: 'viewer', name: 'Viewer' },
	{ id: 'operator', name: 'Operator' },
	{ id: 'customer_admin', name: 'Customer Admin' },
	{ id: 'administrator', name: 'Administrator' }
];

export const WhielistStatuses = [
	{ id: 'pending', name: 'Pending' },
	{ id: 'in progress', name: 'In progress' },
	{ id: 'completed', name: 'Completed' },
	{ id: 'rejected', name: 'Rejected' }
];
