import axios from 'axios';

export default class OrganizationApiKey {
	list() {
		const url = '/api/heimdall/apikeys';

		return new Promise((resolve, reject) => {
			axios
				.get(url)
				.then(keys => resolve(keys.data))
				.catch(ex => reject(ex.response));
		});
	}

	create(body) {
		const url = '/api/heimdall/apikeys';

		return new Promise((resolve, reject) => {
			axios
				.post(url, body)
				.then(keys => resolve(keys.data))
				.catch(ex => reject(ex.response));
		});
	}

	revoke(id) {
		const url = `/api/heimdall/apikeys/${id}`;

		return new Promise((resolve, reject) => {
			axios
				.delete(url)
				.then(keys => resolve(keys.data))
				.catch(ex => reject(ex.response));
		});
	}
}
