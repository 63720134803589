import { DateTime, Interval } from 'luxon';

const isArray = (value) => {
	return Array.isArray(value);
};

const isDateInterval = (value) => {
	return isDateTimeArray(value) || Interval.isInterval(value);
};

const isDateTime = (value) => {
	return value instanceof Date || DateTime.isDateTime(value);
};

const isDateTimeArray = (value) => {
	return isArray(value) && value.every(isDateTime);
};

const isFunction = (value) => {
	return typeof value === 'function';
};

const isObject = (value) => {
	return typeof value === 'object';
};

const isString = (value) => {
	return typeof value === 'string';
};

const toDateTime = (value) => {
	if (isString(value)) {
		return DateTime.fromISO(value, { zone: 'UTC' });
	} else if (value instanceof Date) {
		return DateTime.fromJSDate(value, { zone: 'UTC' });
	}

	return value;
};

const toDateInterval = (value) => {
	if (isDateTimeArray(value)) {
		return Interval.fromDateTimes(...value.map(toDateTime));
	} else if (isString(value)) {
		return Interval.fromISO(value);
	}

	return value;
};

export {
	isArray,
	isDateInterval,
	isDateTime,
	isDateTimeArray,
	isFunction,
	isObject,
	isString,
	toDateTime,
	toDateInterval
};
