import { isRef, getRef } from './ref';
import { isNil } from 'lodash';

export const expandDefinition = (schema, definition) => {
	if (!isRef(definition)) {
		return definition;
	}

	const ref = getRef(definition);

	if (isNil(schema.definitions[ref])) {
		console.warn(`Unable to expand '${ref}'`);
		return null;
	}

	return expandDefinition(schema, schema.definitions[ref]);
};
