import axios from 'axios';

export default class ApiKey {
	list() {
		const url = '/api/apikeys';

		return new Promise((resolve, reject) => {
			axios
				.get(url)
				.then(keys => resolve(keys.data))
				.catch(ex => reject(ex.response));
		});
	}
}
