function eventHandler() {
	let socket = null;
	let socketOpen = false;
	let loggedIn = false;
	let socketRetryTime = 250;
	let initiatingSocket = false;
	let timeout = null;
	let messageQueue = [];
	const callbacks = {};
	const callbacksInternal = {};

	return {
		install(app) {
			app.config.globalProperties.ensureSockets = () => this.ensureSockets();

			app.config.globalProperties.closeSockets = () => {
				// Signal to the component that we're closing the websocket as expected
				// 1000 = closed normally
				if (socketOpen) {
					socket.close(1000);
				}

				socket = null;
				socketOpen = false;
				loggedIn = false;

				if (timeout) {
					clearTimeout(timeout);
				}
			};

			app.config.globalProperties.subscribe = (event, callback) => {
				const isInternal = event.indexOf('_') === 0;
				const cbs = this.getCallbacks(isInternal);

				if (!cbs[event]) {
					cbs[event] = [];
				}
				cbs[event].push(callback);

				if (!isInternal) {
					this.ensureSockets();
					const data = { subscribe: event };
					this.sendMessage(data);
				}
			};

			app.config.globalProperties.unsubscribe = (event, callback) => {
				const isInternal = event.indexOf('_') === 0;

				const cbs = this.getCallbacks(isInternal);

				if (cbs[event]?.length) {
					cbs[event] = cbs[event].filter(cb => cb !== callback);
				}

				if (!isInternal) {
					this.sendMessage({ unsubscribe: event });
				}
			};

			app.config.globalProperties.sendSocketMessage = (args) => this.sendMessage(args);
		},

		async ensureSockets() {
			loggedIn = true;
			const host = window.location.host;
			const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
			timeout = null;

			if (initiatingSocket) {
				// Wait for the socket to be initialized, 250ms for now
				await new Promise(r => setTimeout(r, 250));
				return;
			}

			if (!socketOpen) {
				initiatingSocket = true;
				socket = new WebSocket(`${protocol}//${host}/api/events`);

				socket.onopen = () => {
					socketRetryTime = 250;
					socketOpen = true;
					this.handleMessageQueue(false);
					initiatingSocket = false;
				};
				socket.onmessage = event => this.handleMessage(event.data, false);
				socket.onclose = event => this.handleClose(event);
				socket.onerror = error => this.handleError(error);
			}
		},

		handleMessage(event) {
			const data = JSON.parse(event);
			const name = Object.keys(data)[0];
			const content = data[name];

			const handlers = this.getCallbacks(false)[name];
			if (handlers) {
				handlers.forEach(handler => handler(content));
			}
		},

		handleClose(event) {
			// Websocket was closed successfully (regular shutdown)
			if (event.code === 1000) {
				return;
			}

			socket = null;
			socketOpen = false;
			socketRetryTime *= 2;
			const retryTime = socketRetryTime;

			if (loggedIn) {
				if (!timeout) {
					timeout = setTimeout(() => this.ensureSockets(), retryTime);
				}

				const seconds = retryTime / 1000;
				const message = 'The socket has been unexpectedly closed. Retrying in ' + seconds;
				callbacksInternal._error.forEach(cb => cb({ message }));
			}
		},

		handleError(error) {
			console.log(error);

			const message = 'Something went wrong. Please try again later.';
			callbacksInternal._error.forEach(cb => cb({ message }));
		},

		handleMessageQueue() {
			messageQueue.forEach(message => {
				this.sendMessage(message);
			});
			messageQueue = [];
		},

		async sendMessage(message) {
			if (!loggedIn) {
				return;
			}

			if (!socketOpen) {
				console.log('Unable to send message, socket not yet open, resending message in 2 seconds...');
				await new Promise(r => setTimeout(r, 2000));
				this.sendMessage(message);
				return;
			}

			if (socketOpen) {
				socket.send(JSON.stringify(message));
			} else {
				messageQueue.push(message);
			}
		},

		getCallbacks(internal) {
			if (internal) {
				return callbacksInternal;
			}
			return callbacks;
		}
	};
}

export default eventHandler();
