import { isString } from 'lodash';

const arrayRegex = /\[(?<index>[0-9]+)\]$/;

export default (object, keys) => {
	if (isString(keys)) {
		keys = keys.split('.');
	}

	return keys.reduce((value, key) => {
		if (
			value === null ||
			typeof value === 'undefined'
		) {
			return null;
		}

		if (arrayRegex.test(key)) {
			const index = parseInt(arrayRegex.exec(key).groups.index);
			key = key.replace(arrayRegex, '');

			if (!Array.isArray(value[key])) {
				return null;
			}
			if (value[key].length < index + 1) {
				return null;
			}
			return value[key][index];
		}

		return value[key];
	}, object);
};
