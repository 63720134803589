import Storage from '@/assets/helpers/LocalStorage';

const storage = new Storage('auth');

export const is2FAEnabled = () => storage.get('2FAEnabled');
export const isAuthenticated = () => storage.get('isAuthenticated');
export const isCustomerAdmin = () => storage.get('userType') === 'customer_admin';
export const isOperator = () => storage.get('userType') === 'operator';
export const isAdmin = () => storage.get('userType') === 'administrator';
export const hasCustomerAdminAccess = () => isCustomerAdmin() || isAdmin();
// Elevated access == customer admin or admin _and_ 2fa enabled
export const hasOperatorAccess = () => (hasCustomerAdminAccess() || isOperator()) && is2FAEnabled();
export const elevatedAccessRequirements = (action = 'This action') => `${action} requires a role of customer administrator or operator, and enabled 2FA.`;

function authHandler() {
	return {
		install: (app) => {
			app.config.globalProperties.is2FAEnabled = is2FAEnabled;
			app.config.globalProperties.isAuthenticated = isAuthenticated;
			app.config.globalProperties.isCustomerAdmin = isCustomerAdmin;
			app.config.globalProperties.isAdmin = isAdmin;
			app.config.globalProperties.isOperator = isOperator;
			app.config.globalProperties.hasCustomerAdminAccess = hasCustomerAdminAccess;
			app.config.globalProperties.hasOperatorAccess = hasOperatorAccess;
			app.config.globalProperties.elevatedAccessRequirements = elevatedAccessRequirements;
		}
	};
}

export default authHandler();
