export function getQueryStringParam(key) {
	return new URLSearchParams(location.search).get(key);
}

export function getModifierState(ev, key) {
	return ev.getModifierState(key);
}

export function getEventField(ev, key) {
	return ev[key];
}
