import axios from 'axios';

let isInitiated = false;
let resolveInitiationPromise, features, config;

export const isFeatureEnabled = (feature) =>
	isInitiated && !!features?.[feature];

export const getFeatures = () => features;
export const getConfig = () => config;

export const getEnvironment = () => (config?.environment ?? 'unknown').toLowerCase();

export const environmentHandlerInitiated = new Promise(function(resolve) {
	resolveInitiationPromise = resolve;
});

function environmentHandler() {
	return {
		install: (app) => {
			app.config.globalProperties.initEnvironment = async () => {
				return await axios.get('/api/environment').then(result => {
					const environment = result.data;
					features = environment.features;
					config = environment.config;
					isInitiated = true;
					resolveInitiationPromise();
				});
			};
			app.config.globalProperties.getFeatures = getFeatures;
			app.config.globalProperties.getConfig = getConfig;
			app.config.globalProperties.getEnvironment = getEnvironment;
			app.config.globalProperties.isFeatureEnabled = isFeatureEnabled;
		}
	};
}

export default environmentHandler();
