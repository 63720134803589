import axios from 'axios';

export default class TLE {
	find(noradID) {
		return new Promise((resolve, reject) => axios
			.get('/api/tle/' + noradID)
			.then(response => resolve(response.data))
			.catch(ex => reject(ex.response))
		);
	}

	upload(noradID, tle) {
		const url = '/api/tle/' + noradID;
		return new Promise((resolve, reject) => axios
			.post(url, tle)
			.then(response => resolve(response.data))
			.catch(ex => reject(ex.response))
		);
	}
}
