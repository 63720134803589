// Heavily borrowed from: https://github.com/latelierco/vue-application-insights/blob/master/src/index.js

import { ApplicationInsights, Util, SeverityLevel } from '@microsoft/applicationinsights-web';
import { environmentHandlerInitiated, getConfig } from './EnvironmentHandler';
import { isValidUUID } from '@/assets/utils/uuid';

let insightsInstance = null;
const baseName = 'KSAT Portal';
async function install(app, options) {
	// If we do not have AI enabled or the Instrumentation Key is invalid, don't try to instantiate.
	await environmentHandlerInitiated;

	const applicationInsightString = getConfig().application_insights;

	if (!applicationInsightString) {
		return;
	}

	const config = options.appInsightsConfig || {};
	// The applicationInsightString can be either an Instrumentation Key or a Connection String. Can be removed when in Azure, where we only use connection strings
	if (isValidUUID(applicationInsightString)) {
		config.instrumentationKey = applicationInsightString;
	} else {
		config.connectionString = applicationInsightString;
	}

	if (options.appInsights) {
		app.appInsights = options.appInsights;
	} else {
		app.appInsights = new ApplicationInsights({ config });
		app.appInsights.loadAppInsights();
		if (typeof options.onAfterScriptLoaded === 'function') {
			options.onAfterScriptLoaded(app.appInsights);
		}
	}
	insightsInstance = app.appInsights;
	const router = options.router;

	// Watch route event if router option is defined.
	if (router) {
		if (options.trackInitialPageView === false) {
			setupPageTracking(options, app);
		} else {
			router.isReady().then(() => setupPageTracking(options, app));
		}
	}

	app.config.globalProperties.$monitoring = {
		monitorError
	};
}

/**
 * Track route changes as page views with AppInsights
 * @param options
 */

function setupPageTracking(options, app) {
	const router = options.router;

	router.afterEach(route => {
		const name = baseName + ' / ' + route.name;
		const url = location.protocol + '//' + location.host + route.fullPath;
		app.appInsights.stopTrackPage(name, url);
		app.appInsights.flush();
	});
}

export function monitorBefore(route) {
	if (!insightsInstance) {
		return;
	}
	const name = baseName + ' / ' + route.name;
	insightsInstance.context.telemetryTrace.traceID = Util.generateW3CId();
	insightsInstance.context.telemetryTrace.name = route.name;
	insightsInstance.startTrackPage(name);
}

export function monitorError(error, dataType) {
	if (!insightsInstance) {
		return;
	}
	insightsInstance.trackTrace({
		message: JSON.stringify(error),
		...dataType && { properties: { dataType } }, // Properties can be used to filter traces, for now we only use dataType
		severityLevel: SeverityLevel.Warning
	});
}

export default install;
