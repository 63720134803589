import _ from 'lodash';

function isEmpty(value) {
	return value === null || value === undefined || value === '';
}
function isNested(value) {
	return typeof value === 'object' && !Array.isArray(value) && value !== null;
}

function validateSchemaProperties(required, properties, parametersForm) {
	const errors = {};
	Object.entries(properties).forEach(([property, schema]) => {

		const value = parametersForm[property];

		if (required.includes(property) && !value && value !== 0) {
			errors[property] = { message: `Is missing but is is required` };
			return;
		}

		if (isEmpty(value)) {
			return;
		}

		switch (schema.type) {
			case 'object':
				if (Array.isArray(value) || typeof value !== 'object' || value === null) {
					errors[property] = { message: `Must be an object` };
					break;
				}

				errors[property] = { ...getFormErrors(schema, value) };

				errors[property] = schema.minProperties && Object.keys(value).length < schema.minProperties ? { message: `Must have a minimum of ${schema.minProperties} properties` } : errors[property];
				errors[property] = schema.maxProperties && Object.keys(value).length > schema.maxProperties ? { message: `May only have a maximum of ${schema.maxProperties} properties` } : errors[property];
				break;

			case 'string':

				if (typeof value !== 'string') {
					errors[property] = { message: `Must be a string` };
					break;
				}

				errors[property] = schema.minLength && value.length < schema.minLength ? { message: `Must be at least ${schema.minLength} character(s) long` } : errors[property];
				errors[property] = schema.maxLength && value.length > schema.maxLength ? { message: `May only be ${schema.maxLength} character(s) long` } : errors[property];
				break;

			case 'number':

				if (typeof value !== 'number' || isNaN(value)) {
					errors[property] = { message: `Must be a number` };
					break;
				}

				errors[property] = schema.minimum && value < schema.minimum ? { message: `Must be at least ${schema.minimum}` } : errors[property];
				errors[property] = schema.maximum && value > schema.maximum ? { message: `May only be at most ${schema.maximum}` } : errors[property];

				break;

			case 'array':
				if (!Array.isArray(value)) {
					errors[property] = { message: `Must be an array` };
					break;
				}

				errors[property] = schema.minItems && value.length < schema.minItems ? { message: `Must have at least ${schema.minItems} item(s)` } : errors[property];
				errors[property] = schema.maxItems && value.length > schema.maxItems ? { message: `May only have at most ${schema.maxItems} item(s)` } : errors[property];

				break;

			default:
				break;
		}
		if (errors[property] == undefined || Object.keys(errors[property]).length === 0) {
			delete errors[property];
		}
	});
	return errors;
}


export const getFormErrors = (schema, parametersForm) => {
	let errors = {};

	if (schema.properties) {
		errors = validateSchemaProperties(schema.required ?? [], schema.properties, parametersForm);
	}

	if (schema.patternProperties) {
		// ToDo: Implement validation for patternProperties
	}

	if (schema.additionalProperties) {
		// ToDo: Implement validation for additionalProperties
	}
	return errors;
};

export const getFormChanges = (oldForm, newForm) => {
	const changes = {};
	Object.entries(newForm).forEach(([property, newValue]) => {

		if (isNested(newValue)) {
			const oldValue = oldForm[property];
			const childChanges = getFormChanges(oldValue ?? {}, newValue);

			changes[property] =
				Object.values(childChanges).some(entry => entry?.isChanged)
					? { isChanged: true, ...childChanges }
					: { isChanged: false };
			return;
		}

		if (Array.isArray(newValue)) {
			const oldValue = oldForm[property];
			const arrayChanges = {};

			newValue.forEach((entry) => {
				if (!oldValue.includes(entry)) {
					arrayChanges[entry] = { isChanged: true };
				}
			});

			oldValue.forEach((entry) => {
				if (!newValue.includes(entry)) {
					arrayChanges[entry] = { isChanged: true };
				}
			});

			changes[property] = Object.values(arrayChanges).some(entry => entry?.isChanged)
				? { isChanged: true, ...arrayChanges }
				: { isChanged: false };
			return;

		}

		if (!Object.prototype.hasOwnProperty.call(oldForm, property)) {
			changes[property] = { isChanged: true };
			return;
		}

		if (_.isEqual(newForm[property], oldForm[property])) {
			changes[property] = { isChanged: false };
			return;
		}

		changes[property] = { isChanged: true };
	});
	return changes;
};
