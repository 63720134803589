import predicates, {
	isPredicate,
	isPredicateDefinition
} from './predicates';

import {
	isArray,
	isFunction
} from './types';

/**
 * Factory method that creates a function that run a set of predcates against an object.
 *
 * @param {object} definition of the filter and it's predicates
 * @returns {function}
 */
const createFilter = (filter) => {
	const layers = createLayers(filter);

	return (...args) => {
		return layers.every((layer) => {
			return layer(...args);
		});
	};
};

/**
 * Create a predicate based on value and return a function that:
 * - {@link #pluck|pluck's} a value from and object.
 * - returns the value of running predicate against the plucked value.
 *
 * @param {string} key the property the predicate should be run against
 * @param {object|function} value predicate definition or function
 * @returns {function} a function that
 */
const createLayer = (key, value) => {
	if (isPredicate(value)) {
		return value;
	} else if (isPredicateDefinition(value)) {
		const [method, target] = Object.entries(value)[0];
		const predicate = predicates[method](target);

		return (element) => {
			return predicate(pluck(key, element));
		};
	}

	return createLayer(key, {
		eq: value
	});
};

/**
 * Create multiple filter layers.
 *
 * @param {object} definition of the filter and it's predicates
 * @returns {array} of predicate functions
 */
const createLayers = (filter) => {
	if (isArray(filter)) {
		return filter.map((filter) => {
			const layers = createLayers(filter);

			return (...args) => {
				return layers.every((layer) => {
					return layer(...args);
				});
			};
		});
	} else if (isFunction(filter)) {
		return [
			filter
		];
	}

	return Object.entries(filter).map(([key, value]) => {
		return createLayer(key, value);
	});
};

/**
 * Extract a key from object. Nested values can be extracted with a dot-separated key.
 *
 * @param {string} key path to the value in object
 * @params {object} object to look for the value.
 * @returns the extracted value(if present)
 */
const pluck = (key, object) => {
	if (
		typeof object === 'undefined' ||
		object === null
	) {
		return object;
	}

	if (key.includes('.')) {
		const nextKey = key.replace(/^[^.]+[.$]/, '');
		key = key.replace(/\..+$/, '');

		return pluck(nextKey, object[key]);
	}

	return object[key];
};

export {
	createFilter
};
