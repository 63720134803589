import axios from 'axios';

export default class Contact {
	constructor(from, to) {
		this.from = from;
		this.to = to;
	}

	all() {
		return new Promise((resolve, reject) => {
			let url = '/api/contacts?';
			if (this.from) {
				url += 'from=' + this.from.toISO() + '&';
			}
			if (this.to) {
				url += 'to=' + this.to.toISO();
			}

			axios.get(url)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	get(id) {
		return new Promise((resolve, reject) => {
			axios.get('/api/contacts/' + id)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	add(contact) {
		return new Promise((resolve, reject) => {
			axios.post('/api/contacts', contact)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	edit(contact) {
		return new Promise((resolve, reject) => {
			axios.put('/api/contacts/' + contact.ksat_id, contact)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	cancel(contact) {
		return new Promise((resolve, reject) => {
			axios.delete('/api/contacts/' + contact.ksat_id)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	postPassURL(contactID, from, to, format = 'json') {
		const base = location.protocol + '//' + location.host;
		const url = new URL('/api/postpass/' + encodeURIComponent(contactID), base);

		url.searchParams.set('from', from);
		url.searchParams.set('to', to);
		url.searchParams.set('_format', format);

		return url.href;
	}

	postPassReport(contact, extension = 'json') {
		return new Promise((resolve, reject) => {
			const url = this.postPassURL(contact.contact_id, contact.from.toISO(), contact.to.toISO(), extension);

			axios.get(url)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}
}
