import axios from 'axios';

export default class Releaselog {
	list(filter = { newest: false }) {
		const url = filter.newest ? '/api/releaselog?newest=true' : '/api/releaselog';
		return new Promise((resolve, reject) => {
			axios
				.get(url)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	add(entry) {
		return new Promise((resolve, reject) => {
			axios
				.post('/api/releaselog', entry)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	edit(entry) {
		return new Promise((resolve, reject) => {
			axios
				.post('/api/releaselog/' + entry.id, entry)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	remove(id) {
		return new Promise((resolve, reject) => {
			axios
				.delete('/api/releaselog/' + id)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}
}
