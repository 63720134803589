// Hack because we can't use crypto.randomUUID in non-https addresses (or localhost)
const randomUUID = () => {
	return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c => (
		c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
	);
};

const isValidUUID = (uuid) => /^\{?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\}?$/.test(uuid);

export {
	randomUUID,
	isValidUUID
};
