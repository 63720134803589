function defaultOptions() {
	return {
		maintainAspectRatio: false,
		responsive: true,
		elements: {
			point: {
				radius: 0,
				hitRadius: 10
			}
		},
		animation: {
			duration: 0
		},
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				mode: 'index',
				intersect: false
			}
		},
		scales: {
			x: {
				ticks: {
					maxTicksLimit: 2.1,
					maxRotation: 0,
					color: 'hsl(0, 0%, 80%)'
				}
			}
		}
	};
}

function defaultOptionsPostpass() {
	return {
		maintainAspectRatio: false,
		responsive: true,
		elements: {
			point: {
				radius: 0,
				hitRadius: 10
			}
		},
		animation: {
			duration: 0
		},
		plugins: {
			legend: {
				display: true,
				position: 'bottom',
				labels: {
					color: 'hsl(0, 0%, 100%)',
					font: {
						family: 'Roboto Condensed',
						size: 16,
						weight: 'bold'
					}
				}
			},
			tooltip: {
				mode: 'index',
				intersect: false
			}
		},
		scales: {
			x: {
				ticks: {
					maxTicksLimit: 9.1,
					color: 'hsl(0, 0%, 80%)',
					font: {
						family: 'Roboto Condensed',
						weight: 'bold'
					},
					maxRotation: 0
				}
			}
		}
	};
}

function horizonOptions() {
	return {
		maintainAspectRatio: false,
		responsive: true,
		elements: {
			point: {
				radius: 0,
				hitRadius: 10
			}
		},
		animation: {
			duration: 0
		},
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				display: false
			}
		},
		scales: {
			x: {
				title: {
					display: true,
					text: 'Azimuth',
					font: {
						family: 'Roboto Condensed',
						size: 14,
						weight: 'bold'
					},
					color: 'hsl(0, 0%, 65%)'
				},
				ticks: {
					maxTicksLimit: 8,
					color: 'hsl(0, 0%, 55%)'
				}
			},
			y: {
				title: {
					display: true,
					text: 'Elevation',
					font: {
						family: 'Roboto Condensed',
						size: 14,
						weight: 'bold'
					},
					color: 'hsl(0, 0%, 65%)'
				},
				ticks: {
					color: 'hsl(0, 0%, 55%)'
				}
			}
		}
	};
}

const trackingYAxes = {
	azimuth: {
		position: 'right',
		suggestedMax: 360,
		beginAtZero: true,
		ticks: {
			color: 'hsl(40, 90%, 60%)',
			stepSize: 45
		}
	},
	elevation: {
		position: 'right',
		suggestedMax: 90,
		beginAtZero: true,
		ticks: {
			color: 'hsl(153, 48%, 44%)',
			stepSize: 15
		}
	}
};

const uplinkYAxes = {
	commandsSent: {
		position: 'right',
		ticks: {
			color: 'hsl(40, 90%, 60%)'
		}
	},
	power: {
		position: 'right',
		suggestedMax: 50,
		ticks: {
			color: 'hsl(153, 48%, 44%)'
		}
	},
	commandsDiscarded: {
		position: 'right',
		ticks: {
			color: 'hsl(202, 90%, 60%)'
		}
	}
};

const sBandYAxes  = {
	db: {
		position: 'right',
		ticks: {
			color: 'hsl(40, 90%, 60%)'
		}
	},
	dbm: {
		position: 'right',
		ticks: {
			color: 'hsl(153, 48%, 44%)'
		}
	}
};

const xBandYAxes = {
	esnoI: {
		position: 'right',
		ticks: {
			color: 'hsl(40, 90%, 60%)'
		}
	},
	totalBlockErrorRate: {
		position: 'right',
		ticks: {
			color: 'hsl(153, 48%, 44%)'
		}
	}
};

function createOptions(yAxes, postpass = false) {
	const options = postpass ? defaultOptionsPostpass() : defaultOptions();
	options.scales = { ...options.scales, ...yAxes };
	return options;
}

const trackingOptions = () => createOptions(trackingYAxes);
const uplinkOptions = () => createOptions(uplinkYAxes);
const sBandOptions = () => createOptions(sBandYAxes);
const xBandOptions = () => createOptions(xBandYAxes);

const trackingOptionsPostpass = () => createOptions(trackingYAxes, true);
const uplinkOptionsPostpass = () => createOptions(uplinkYAxes, true);
const sBandOptionsPostpass = () => createOptions(sBandYAxes, true);
const xBandOptionsPostpass = () => createOptions(xBandYAxes, true);

export default {
	trackingOptions,
	uplinkOptions,
	sBandOptions,
	xBandOptions,
	trackingOptionsPostpass,
	uplinkOptionsPostpass,
	sBandOptionsPostpass,
	xBandOptionsPostpass,
	horizonOptions
};
