import axios from 'axios';

export default class Authentication {
	login(email, password, remember) {
		return new Promise((resolve, reject) => {
			axios
				.post('/api/auth/native/login', { email, password, remember })
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	login2FA(email, code) {
		return new Promise((resolve, reject) => {
			axios
				.post('/api/2fa/login', { email, code })
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	state() {
		return new Promise((resolve, reject) => {
			axios
				.get('/api/session/state')
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	setTenant(tenantId) {
		return new Promise((resolve, reject) => {
			axios
				.post('/api/session/tenant', { tenant_id: tenantId })
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	resetPasswordToken(email) {
		return new Promise((resolve, reject) => {
			axios
				.post('/api/auth/native/token?email=' + email)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	resetPassword(token, password) {
		return new Promise((resolve, reject) => {
			axios
				.post('/api/auth/native/reset', {
					token: token,
					password: password
				})
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	enable2FA(code) {
		return new Promise((resolve, reject) => {
			axios
				.post('/api/2fa/enable', { code })
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	status2FA() {
		return new Promise((resolve, reject) => {
			axios
				.get('/api/2fa/status')
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	reset2FA() {
		return new Promise((resolve, reject) => {
			axios
				.post('/api/2fa/reset')
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	logout() {
		return new Promise((resolve, reject) => {
			axios
				.get('/api/logout')
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}
}
