import axios from 'axios';

const baseURL = '/api/systems';
export default class System {
	static programs() {
		return [
			{ name: 'KSAT', value: 'ksat' },
			{ name: 'Lite', value: 'lite' }
		];
	}

	static findProgram(value) {
		return this.programs().find(program => program.value === value);
	}

	profile(system) {
		const url = baseURL + '/' + system + '/profile';
		return new Promise((resolve, reject) => {
			axios
				.get(url)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	capabilities(system) {
		const url = baseURL + '/' + system + '/capabilities';
		return new Promise((resolve, reject) => {
			axios
				.get(url)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	allCapabilities() {
		return new Promise((resolve, reject) => {
			axios
				.get(baseURL + '/capabilities')
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	setCapabilities(system, capabilities) {
		const url = baseURL + '/' + system + '/capabilities';
		return new Promise((resolve, reject) => {
			axios
				.post(url, capabilities)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	ports() {
		return new Promise((resolve, reject) => {
			axios
				.get(baseURL + '/ports')
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}
}
