import { DateTime, Duration } from 'luxon';
import Lodash from '@/assets/helpers/Lodash';

function epoch(time) {
	return DateTime
		.fromISO(time)
		.toUTC()
		.toMillis();
}

function pastRealtimePostpass(realtime) {
	const mapped = {};

	realtime.services.forEach(service => {
		mapped[service.name] = [];

		service.measurements.forEach(measurement => {
			mapped[service.name].push(measurement);
		});

		mapped[service.name] = new Lodash(mapped[service.name])
			.sortBy(measurement => epoch(measurement.time))
			.value();
	});

	return mapped;
}

function timeline(contact) {
	let from = DateTime.fromISO(contact.from).toUTC();
	const length = contactLength(contact);
	const timeline = [];

	for (let i = 0; i <= length; i++) {
		timeline.push(from.toFormat('HH:mm'));
		from = from.plus({ seconds: 1 });
	}

	return timeline;
}

function timelinePostpass(reportData) {
	return new Lodash(reportData)
		.map(measurement => DateTime
			.fromISO(measurement.time)
			.toUTC()
			.toFormat('dd-LL-yy HH:mm:ss'))
		.uniq()
		.value();
}

function fillNullValues(array, stopIndex) {
	if (!stopIndex) {
		stopIndex = array.length;
	}
	let lastValue;

	return new Lodash(array.slice(0, stopIndex + 1))
		.map(item => {
			if (item) {
				lastValue = item;
				return item;
			}

			return lastValue;
		})
		.value();
}

function positionInArray(contact, countdown) {
	return contactLength(contact) - remainingSeconds(countdown);
}

function contactLength(contact) {
	return contact.to.diff(contact.from).as('seconds');
}

function remainingSeconds(countdown) {
	const remaining = Duration.fromISOTime(countdown.substring(1));
	return remaining.as('seconds');
}

function contactStart(contact) {
	return DateTime
		.fromISO(contact.from)
		.toUTC()
		.toMillis() / 1000;
}

function stringToSeconds(timeString) {
	return DateTime
		.fromISO(timeString)
		.toUTC()
		.toMillis() / 1000;
}

function mapPastRealtime(measurementName, contactStart, allMeasurements) {
	const result = [];
	const now = DateTime.utc().toMillis() / 1000;
	const nowPosition = Math.round(now - contactStart);
	const measurements = allMeasurements.filter(m => m.name === measurementName);
	measurements.forEach(measurement => {
		let position = Math.round(stringToSeconds(measurement.time) - contactStart);
		// If we can't find a position or it's in the future, they're invalid and we discard.
		if (isNaN(position) || position > nowPosition) {
			return;
		}
		// If it's before AOS we set it as AOS (This was discarded earlier, but I think we lost some stale data like that. Not sure what is best
		if (position < 0) {
			position = 0;
		}
		result[position] = parseFloat(measurement.value);
	});
	return fillNullValues(result, nowPosition);
}

export { pastRealtimePostpass, timeline, timelinePostpass, fillNullValues, positionInArray, contactStart, mapPastRealtime };
