import _cloneDeep from 'lodash/cloneDeep';
import _debounce from 'lodash/debounce';
import _each from 'lodash/each';
import _filter from 'lodash/filter';
import _flatten from 'lodash/flatten';
import _groupBy from 'lodash/groupBy';
import _last from 'lodash/last';
import _map from 'lodash/map';
import _reverse from 'lodash/reverse';
import _sortBy from 'lodash/sortBy';
import _orderBy from 'lodash/orderBy';
import _isEqual from 'lodash/isEqual';
import _take from 'lodash/take';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';
import _differenceWith from  'lodash/differenceWith';

// This class exists to easily keep down the bundle size of Lodash
// and simplify the new way of using Lodash in the application.
export default class Lodash {
	constructor(content = null) {
		this.content = content;
	}

	// ------ Isolated methods ------
	debounce(method, time) {
		return _debounce(method, time);
	}

	groupBy(expression) {
		return _groupBy(this.content, expression);
	}

	last() {
		return _last(this.content);
	}

	differenceWith(array1, array2, comparator) {
		return _differenceWith(array1, array2, comparator);
	}

	equals(object1, object2) {
		return _isEqual(object1, object2);
	}

	value() {
		return this.content;
	}

	// ------ Methods able to continue a chain ------
	cloneDeep() {
		this.content = _cloneDeep(this.content);
		return this;
	}

	each(expression) {
		this.content = _each(this.content, expression);
		return this;
	}

	filter(expression) {
		this.content = _filter(this.content, expression);
		return this;
	}

	flatten() {
		this.content = _flatten(this.content);
		return this;
	}

	map(expression) {
		this.content = _map(this.content, expression);
		return this;
	}

	sortBy(expression) {
		this.content = _sortBy(this.content, expression);
		return this;
	}

	take(amount) {
		this.content = _take(this.content, amount);
		return this;
	}

	reverse() {
		this.content = _reverse(this.content);
		return this;
	}

	uniq() {
		this.content = _uniq(this.content);
		return this;
	}

	uniqBy(expression) {
		this.content = _uniqBy(this.content, expression);
		return this;
	}

	orderBy(fields, directions) {
		this.content = _orderBy(this.content, fields, directions);
		return this;
	}

	// ------ Custom methods able to continue a chain ------

	sortByAlphaNum(field) {
		this.content = this.content.sort((a, b) => a[field].toString().localeCompare(b[field].toString(), undefined, { numeric: true }));
		return this;
	}

	/**
	 * Sort function that sorts an array of objects by a specific field, against a custom sort order.
	 * field = name of property to sort by
	 * sortOrder = array of possible values in prefred order i.eg. ['available', 'conflict', 'occupied', 'unavailable'] for sytem states
	 */
	sortByCustomOrder(field, sortOrder = []) {
		this.content = this.content.sort((a, b) => {
			return sortOrder.indexOf(a[field]) - sortOrder.indexOf(b[field]);
		});
		return this;
	}
}
