
import { settings } from './ChartMetricsSettings';

const chartColors = [
	'hsl(40, 90%, 60%)',
	'hsl(153, 48%, 44%)',
	'hsl(202, 90%, 60%)',
	'hsl(252, 70%, 60%)'
];

function defaultOptions() {
	return {
		maintainAspectRatio: false,
		responsive: true,
		elements: {
			point: {
				radius: 0,
				hitRadius: 10
			}
		},
		animation: {
			duration: 0
		},
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				mode: 'index',
				intersect: false,
				callbacks: {
					label: tooltipLabelCallback,
					labelColor: tooltipLabelColorCallback
				}
			}
		},
		scales: {
			x: {
				type: 'time',
				time: {
					unit: 'minute',
					tooltipFormat: 'HH:mm:ss',
					displayFormats: {
						minute: 'HH:mm'
					}
				},
				ticks: {
					maxRotation: 0,
					maxTicksLimit: 2.1,
					color: 'hsl(0, 0%, 80%)'
				}
			}
		}
	};
}

function createYAxis(metric, index) {
	const yAxis = {
		position: 'right',
		ticks: {
			color: chartColors[index]
		}
	};

	const metricSetting = settings.find(setting => setting.metric === metric.metric);

	if (metricSetting) {
		if (metricSetting.stepSize) {
			yAxis.ticks.stepSize = metricSetting.stepSize;
		}
		if (metricSetting.suggestedMax) {
			yAxis.suggestedMax = metricSetting.suggestedMax;
		}
		if (metricSetting.beginAtZero) {
			yAxis.beginAtZero = metricSetting.beginAtZero;
		}
	}

	if (metric.type === 'string') {
		yAxis.ticks.callback = (value) => {
			if (!metric.labels) {
				return value;
			}

			const label = metric.labels[value];
			return label ? label[0] : null;
		};
	}

	return yAxis;
}

function createDataset(metric, index, yAxisID) {
	return {
		id: metric.metric,
		label: metric.name,
		borderColor: chartColors[index],
		borderWidth: 2,
		yAxisID,
		metric: metric,
		data: []
	};
}

function mapDashboardSettings(settings, metrics) {
	const mappedColumns = settings.columns.map(column => {
		const mappedChartMetrics = column.chartMetrics.map(chartMetric => {
			return metrics.find(m => m.metric === chartMetric);
		});
		const mappedTableMetrics = column.tableMetrics.map(tableMetric => {
			const metric = metrics.find(m => m.metric === tableMetric);
			const chartIndex = column.chartMetrics.findIndex(m => m === tableMetric);
			metric.chartColor = chartIndex === -1 ? null : chartColors[chartIndex];
			return metric;
		});

		return {
			...column,
			chartMetrics: mappedChartMetrics,
			tableMetrics: mappedTableMetrics
		};
	});

	return { columns: mappedColumns };
}

function handlePastRealtime(pastRealtime, metrics) {
	// Removing first set of values since it's often misleading (first can be azimuth 180 and jumps to 0 on second value)
	if (pastRealtime.timestamps.length) {
		pastRealtime.timestamps.shift();

		pastRealtime.measurements.forEach(measurement => {
			measurement.values.shift();
		});
	}

	const mappedMeasurements = pastRealtime.measurements.map(measurement => {
		const metric = metrics.find(m => m.metric === measurement.metric);

		const mappedValues = measurement.values.map(value => {
			if (!value && value !== 0) {
				return null;
			}

			switch (metric.type) {
				case 'int': return parseInt(value);
				case 'float': return parseFloat(value);
			}
			return value;
		});

		let labelIndexes = null;

		if (metric.type === 'string') {
			const uniqueValues = [...new Set(measurement.values)];
			addToMetricLabels(metric, uniqueValues);

			labelIndexes = measurement.values.map(value => {
				if (!value) {
					return null;
				}
				return metric.labels.indexOf(value);
			});
		}

		return {
			...measurement,
			values: mappedValues,
			labelIndexes
		};
	});

	return { ...pastRealtime, measurements: mappedMeasurements };
}

function handleRealtime(realtime, metrics) {
	const mappedMeasurements = realtime.measurements.map(measurement => {
		const metric = metrics.find(m => m.metric === measurement.metric);

		let value = measurement.value;
		let labelIndex = 0;

		if (!value && value !== 0) {
			return { ...measurement, value: null };
		}

		switch (metric.type) {
			case 'int': value = parseInt(value); break;
			case 'float': value = parseFloat(value); break;
			case 'string': {
				addToMetricLabels(metric, [value]);
				labelIndex = metric.labels.indexOf(value);
				return { ...measurement, value, labelIndex };
			}
		}

		return { ...measurement, value };
	});

	return { ...realtime, measurements: mappedMeasurements };
}

function addRealtimeToArchive(archive, realtime) {
	archive.timestamps.push(realtime.timestamp);
	realtime.measurements.forEach(measurement => {
		const archiveMeasurement = archive.measurements.find(m => m.metric === measurement.metric);
		archiveMeasurement.values.push(measurement.value);

		if (archiveMeasurement.labelIndexes) {
			archiveMeasurement.labelIndexes.push(measurement.labelIndex);
		}
	});
}

function tooltipLabelCallback(tooltipItem) {
	const dataset = tooltipItem.dataset;
	const metric = dataset.metric;
	if (metric.type === 'string' && metric.labels && metric.labels[tooltipItem.raw.y]) {
		return dataset.label + ': ' + metric.labels[tooltipItem.raw.y];
	}
	return dataset.label + ': ' + tooltipItem.formattedValue;
}

function tooltipLabelColorCallback(tooltipItem) {
	return {
		backgroundColor: tooltipItem.dataset.borderColor
	};
}

function addToMetricLabels(metric, labels) {
	if (metric.labels) {
		const missingValues = labels.filter(value => metric.labels.indexOf(value) === -1);
		metric.labels = [...metric.labels, ...missingValues];
	} else {
		metric.labels = labels;
	}
}

export default {
	defaultOptions,
	createYAxis,
	createDataset,
	mapDashboardSettings,
	handlePastRealtime,
	handleRealtime,
	addRealtimeToArchive
};
