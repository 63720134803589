import axios from 'axios';

export default class User {
	constructor() {
		this.baseUrl = '/api/users';
	}

	list() {
		return new Promise((resolve, reject) => {
			axios
				.get(this.baseUrl)
				.then(users => resolve(users.data))
				.catch(ex => reject(ex.response));
		});
	}

	add(user) {
		return new Promise((resolve, reject) => {
			axios
				.post(this.baseUrl, user)
				.then(user => resolve(user.data))
				.catch(ex => reject(ex.response));
		});
	}

	update(user) {
		const url = this.baseUrl + '/' + user.id;

		return new Promise((resolve, reject) => {
			axios
				.post(url, user)
				.then(user => resolve(user.data))
				.catch(ex => reject(ex.response));
		});
	}

	remove2FA(user) {
		const url = '/api/2fa/' + user.id + '/disable';

		return new Promise((resolve, reject) => {
			axios
				.post(url, user)
				.then(user => resolve(user.data))
				.catch(ex => reject(ex.response));
		});
	}

	delete(id) {
		const url = this.baseUrl + '/' + id;

		return new Promise((resolve, reject) => {
			axios
				.delete(url)
				.then(user => resolve(user.data))
				.catch(ex => reject(ex.response));
		});
	}
}
