export default class LocalStorage {
	constructor(base) {
		this.base = base + '-';
	}

	save(key, value) {
		localStorage.setItem(this.base + key, JSON.stringify(value));
	}

	get(key) {
		return JSON.parse(localStorage.getItem(this.base + key));
	}

	remove(key) {
		localStorage.removeItem(this.base + key);
	}
}
