// Contains icons that are meant to be available globally

import { faFileAlt } from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import { faFileCode } from '@fortawesome/pro-solid-svg-icons/faFileCode';
import { faFileDownload } from '@fortawesome/pro-solid-svg-icons/faFileDownload';
import { faFileLines } from '@fortawesome/pro-solid-svg-icons/faFileLines';

import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faUserLock } from '@fortawesome/pro-solid-svg-icons/faUserLock';

import { faAngleUp } from '@fortawesome/pro-solid-svg-icons/faAngleUp';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';

import { faArrowUp } from '@fortawesome/pro-solid-svg-icons/faArrowUp';
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons/faArrowDown';
import { faArrowUpToLine } from '@fortawesome/pro-solid-svg-icons/faArrowUpToLine';
import { faArrowDownToLine } from '@fortawesome/pro-solid-svg-icons/faArrowDownToLine';

import { faList } from '@fortawesome/pro-solid-svg-icons/faList';
import { faListAlt } from '@fortawesome/pro-solid-svg-icons/faListAlt';

import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';

import { faMapMarker } from '@fortawesome/pro-solid-svg-icons/faMapMarker';
import { faMapMarkedAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkedAlt';

import { faComments } from '@fortawesome/pro-solid-svg-icons/faComments';
import { faCommentDots } from '@fortawesome/pro-solid-svg-icons/faCommentDots';

import { faWifi } from '@fortawesome/pro-solid-svg-icons/faWifi';
import { faWifiSlash } from '@fortawesome/pro-solid-svg-icons/faWifiSlash';

import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons/faPaperPlane';
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons/faPaperclip';

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';

import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons/faTrashCan';

import { faSatellite } from '@fortawesome/pro-solid-svg-icons/faSatellite';
import { faSatelliteDish } from '@fortawesome/pro-solid-svg-icons/faSatelliteDish';

import { faChartBar } from '@fortawesome/pro-solid-svg-icons/faChartBar';
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faWrench } from '@fortawesome/pro-solid-svg-icons/faWrench';
import { faCalendar } from '@fortawesome/pro-solid-svg-icons/faCalendar';
import { faTachometerAlt } from '@fortawesome/pro-solid-svg-icons/faTachometerAlt';
import { faNetworkWired } from '@fortawesome/pro-solid-svg-icons/faNetworkWired';
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faPowerOff } from '@fortawesome/pro-solid-svg-icons/faPowerOff';
import { faWindowMaximize } from '@fortawesome/pro-solid-svg-icons/faWindowMaximize';
import { faLongArrowAltRight } from '@fortawesome/pro-solid-svg-icons/faLongArrowAltRight';
import { faLayerGroup } from '@fortawesome/pro-solid-svg-icons/faLayerGroup';
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV';
import { faSort } from '@fortawesome/pro-solid-svg-icons/faSort';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faExpandArrowsAlt } from '@fortawesome/pro-solid-svg-icons/faExpandArrowsAlt';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { faHeartRate } from '@fortawesome/pro-solid-svg-icons/faHeartRate';
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell';
import { faKey } from '@fortawesome/pro-solid-svg-icons/faKey';
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons/faEyeSlash';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faBook } from '@fortawesome/pro-solid-svg-icons/faBook';
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faUpload } from '@fortawesome/pro-solid-svg-icons/faUpload';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faPuzzlePiece } from '@fortawesome/pro-solid-svg-icons/faPuzzlePiece';
import { faAngle } from '@fortawesome/pro-solid-svg-icons/faAngle';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faShieldBlank } from '@fortawesome/pro-solid-svg-icons/faShieldBlank';
import { faShield } from '@fortawesome/pro-solid-svg-icons/faShield';
import { faHourglass } from '@fortawesome/pro-solid-svg-icons/faHourglass';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons/faLocationDot';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp';
import { faSquare as farSquare } from '@fortawesome/pro-regular-svg-icons/faSquare';
import { faCheckSquare as farCheckSquare } from '@fortawesome/pro-regular-svg-icons/faCheckSquare';
import { faCircle as farCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faDotCircle as farDotCircle } from '@fortawesome/pro-regular-svg-icons/faDotCircle';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion';
import { faCode } from '@fortawesome/pro-solid-svg-icons/faCode';
import { faDesktop } from '@fortawesome/pro-solid-svg-icons/faDesktop';
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons/faBuilding';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons/faEllipsisH';

/* eslint-disable */
export default {
	faChartBar, faBars, faWrench, faCalendar, faList, faTachometerAlt, faMapMarkedAlt, faSatellite,
	faNetworkWired, faFileAlt, faFileCode, faCog, faListAlt, faUser, faPowerOff, faWindowMaximize,
	faLongArrowAltRight, faLayerGroup, faDownload, faAngleUp, faAngleLeft, faAngleRight, faEllipsisV, faAngleDown,
	faCommentDots, faTimes, faPaperPlane, faMapMarker, faTrash, faSort, faEye, faExpandArrowsAlt,
	faExclamationTriangle, faPlus, faPencil, faHeartRate, faBell, faComments, faWifi, faUserLock,
	faUsers, faKey, faLock, faEyeSlash, faSpinner, faBook, faFileDownload, faImage, faPaperclip,
	faExclamationCircle, faCheckCircle, faTimesCircle, faFilter, faArrowUp, faArrowDown, faArrowUpToLine, faArrowDownToLine,
	faFileLines, faWifiSlash, faTrashCan, faUpload, faEnvelope, faSatelliteDish, faCheck, faClock, faChevronRight,
	faChevronDown, faPuzzlePiece, faAngle, faInfoCircle, faShieldBlank, faShield, faHourglass, faLocationDot,
	farSquare, farCheckSquare, farCircle, farDotCircle, faCaretDown, faCaretUp, faQuestion, faCode, faDesktop, faCopy,
	faBuilding, faEllipsisH
};
/* eslint-enable */
