import Vuex from 'vuex';
import Lodash from '@/assets/helpers/Lodash';
import { DateTime } from 'luxon';

import Asset from '@/assets/helpers/Asset';
import Tenant from '@/assets/helpers/Tenant';
import Contact from '@/assets/helpers/Contact';
import Alert from '@/assets/helpers/Alert';
import Releaselog from '@/assets/helpers/Releaselog';
import Organization from '@/assets/helpers/Organization';
import MetricDefinition from '@/assets/helpers/MetricDefinition';
import contactUtils from '@/assets/utils/contact';
import { isAdmin } from '@/assets/plugins/AuthHandler';
import { EventBus } from '@/assets/plugins/EventBus';

const store = new Vuex.Store({
	state: {
		promises: {
			assets: null,
			stations: null,
			systems: null,
			spacecrafts: null,
			contacts: null,
			organizations: null
		},
		tenant: null,
		stations: null,
		systems: null,
		spacecrafts: null,
		tenants: null,
		contacts: null,
		missionProfiles: null,
		metricDefinitions: null,
		contactsTimespan: {
			from: DateTime.utc(),
			to: DateTime.utc()
		},
		lastFetchContactsTimespan: {
			from: DateTime.utc(),
			to: DateTime.utc()
		},
		defaultContactsTimespanEnabled: true,
		now: DateTime.utc(),
		organizations: null,
		alerts: null,
		newestReleaselog: null,
		seenReleaselog: false
	},
	mutations: {
		tenant(state, payload) {
			state.tenant = payload.tenant;
		},
		stations(state, payload) {
			state.stations = payload.stations;
		},
		systems(state, payload) {
			state.systems = payload.systems;
		},
		spacecrafts(state, payload) {
			state.spacecrafts = payload.spacecrafts;
		},
		tenants(state, payload) {
			state.tenants = payload.tenants;
		},
		missionProfiles(state, payload) {
			state.missionProfiles = payload.missionProfiles;
		},
		spacecraft(state, payload) {
			state.spacecrafts.map(s => {
				return s.id === payload.id ? { ...s, ...payload } : s;
			});
		},
		contacts(state, payload) {
			state.contacts = payload.contacts;
		},
		metricDefinitions(state, payload) {
			state.metricDefinitions = payload.metricDefinitions;
		},
		addContact(state, payload) {
			if (state.contacts) {
				state.contacts.push(payload);
			} else {
				state.contacts = [payload];
			}
		},
		removeContact(state, payload) {
			state.contacts = state.contacts?.filter(contact => contact.ksat_id !== payload.ksat_id);
		},
		contactsTimespan(state, payload) {
			state.contactsTimespan = payload.timespan;
		},
		lastFetchContactsTimespan(state, payload) {
			state.lastFetchContactsTimespan = payload.timespan;
		},
		defaultContactsTimespanEnabled(state, payload) {
			state.defaultContactsTimespanEnabled = payload;
		},
		alerts(state, payload) {
			state.alerts = payload.alerts;
		},
		organizations(state, payload) {
			state.organizations = payload.organizations;
		},
		newestReleaselog(state, payload) {
			state.newestReleaselog = payload.newestReleaselog;
		},
		seenReleaselog(state, payload) {
			state.seenReleaselog = payload.seenReleaselog;
		},
		promises(state, payload) {
			state.promises = { ...state.promises, ...payload };
		},
		updateTime(state) {
			state.now = DateTime.utc();
		},
		resetStore(state, payload) {
			state.promises = {
				assets: null,
				stations: null,
				systems: null,
				spacecrafts: null,
				contacts: null,
				organizations: null
			};
			state.stations = null;
			state.systems = null;
			state.spacecrafts = null;
			state.contacts = null;
			state.missionProfiles = null;

			if (!payload.tenantChanged) {
				state.tenants = null;
			}

			if (payload.logout) {
				state.organizations = null;
				state.alerts = null;
			}
		}
	},
	getters: {
		findStation: state => (id, field = 'id') => {
			return (state.stations || []).find(station => station[field] === id);
		},
		findSpacecraft: state => (id, field = 'id') => {
			return (state.spacecrafts || []).find(spacecraft => spacecraft[field] === id);
		},
		findSystem: state => (id, field = 'id') => {
			return (state.systems || []).find(system => system[field] === id);
		},
		findOrganization: state => (value, field = 'id') => {
			return (state.organizations || []).find(organization => organization[field] === value);
		},
		findTenant: state => (value, field = 'id') => {
			return (state.tenants || []).find(tenant => tenant[field] === value);
		},
		findMissionProfile: state => (value, field = 'id') => {
			return (state.missionProfiles || []).find(missionProfile => missionProfile[field] === value);
		},
		mapColumn: () => (column, extra) => {
			const additional = {
				active: null,
				activeStation: '',
				next: null,
				countdown: ''
			};
			return { ...column, ...additional, ...extra };
		},
		mapContact: (state, getters) => contact => {
			const station = getters.findStation(contact.station_id);
			const foundStation = !!station;

			const system = getters.findSystem(contact.system_id);
			const foundSystem = !!system;

			const missionProfile = getters.findMissionProfile(contact.mission_profile_id);
			const foundMissionProfile = !!missionProfile;

			const spacecraft = getters.findSpacecraft(contact.spacecraft_id);
			const from = DateTime.fromISO(contact.start).toUTC();
			const to = DateTime.fromISO(contact.end).toUTC();
			const status = to < state.now ? 'completed' : from < state.now ? 'active' : 'incoming';

			return {
				name: spacecraft.name,
				ksat_id: contact.ksat_id,
				contact_id: contact.contact_id,
				station: foundStation ? station.name : 'Unknown station',
				station_id: foundStation ? station.id : null,
				system: foundSystem ? system.name : 'Unknown system',
				system_id: foundSystem ? system.id : null,
				mission_profile: foundMissionProfile ? missionProfile.name : '',
				mission_profile_id: foundMissionProfile ? missionProfile.id : null,
				spacecraft: spacecraft.name,
				spacecraft_id: spacecraft.id,
				from: from,
				to: to,
				duration: to.diff(from),
				status: status,
				found_assets: foundStation && foundSystem && foundMissionProfile,
				parameters: contact.parameters
			};
		},
		expandContact: (state, getters) => (contact) => {
			const tenant = getters.findTenant(contact.tenant_id);
			const station = getters.findStation(contact.station_id);
			const system = getters.findSystem(contact.system_id);
			const spacecraft = getters.findSpacecraft(contact.spacecraft_id);
			const missionProfile = getters.findMissionProfile(contact.mission_profile_id);
			const from = DateTime.fromISO(contact.start).toUTC();
			const to = DateTime.fromISO(contact.end).toUTC();

			const expanded = {
				ksat_id: contact.ksat_id,
				contact_id: contact.contact_id,
				services: contact.services,
				state: contact.state,
				parameters: contact.parameters,
				parameterSchemaId: contact.parameter_schema_id,
				tenant,
				station,
				system,
				spacecraft,
				missionProfile,
				from,
				to
			};
			contactUtils.setTimeIntervals(expanded);
			return expanded;
		},
		getContactsFor: (state, getters) => (id, type) => {
			const field = type === 'spacecraft' ? 'spacecraft_id' : 'station_id';
			return new Lodash(state.contacts)
				.filter(contact => contact[field] === id)
				.sortBy('start')
				.filter(contact => getters.findSpacecraft(contact.spacecraft_id))
				.map(contact => getters.mapContact(contact))
				.value();
		},
		getActiveAlerts: state => {
			return (state.alerts || []).filter(alert => alert.active);
		},
		enabledStations: state => {
			return (state.stations || []).filter(station => station.enabled);
		},
		enabledSystems: state => {
			return (state.systems || []).filter(system => system.enabled);
		}
	},
	actions: {
		startTime({ commit }) {
			return setInterval(() => commit('updateTime'), 1000);
		},
		ensureData({ state, commit, dispatch }) {
			dispatch('ensureAssets');
			dispatch('ensureTenants');
			dispatch('ensureContacts');
			dispatch('ensureMetricDefinitions');

			if (isAdmin()) {
				dispatch('ensureOrganizations');
			}

			return new Promise((resolve, reject) => {
				Promise.all(Object.values(state.promises))
					.then(() => {
						commit('promises', {
							stations: null,
							systems: null,
							spacecrafts: null,
							contacts: null
						});

						resolve();
					})
					.catch(ex => {
						reject(ex);
					});
			});
		},
		refreshData({ state, commit, dispatch }) {
			if (state.defaultContactsTimespanEnabled) {
				dispatch('setDefaultContactsTimespan');
			}

			dispatch('refreshAssets');
			dispatch('refreshTenants');
			dispatch('refreshContacts');

			if (isAdmin()) {
				dispatch('refreshOrganizations');
			}

			return new Promise((resolve, reject) => {
				Promise.all(Object.values(state.promises))
					.then(() => {
						commit('promises', {
							stations: null,
							systems: null,
							spacecrafts: null,
							contacts: null,
							tenants: null,
							assets: null
						});

						resolve();
					})
					.catch(ex => {
						reject(ex);
					});
			});
		},
		ensureTenants({ state, dispatch }) {
			if (state.promises.tenants) {
				return state.promises.tenants;
			}
			if (state.tenants) {
				return new Promise(resolve => resolve());
			}

			return dispatch('refreshTenants');
		},
		refreshTenants({ state, commit }) {
			if (state.promises.tenants) {
				return state.promises.tenants;
			}

			const promise = new Tenant()
				.list()
				.then(tenants => {
					commit('tenants', { tenants });
				})
				.catch(ex => EventBus.emit('exception', { ex, message: 'Unable to fetch all tenants', type: 'error' }));

			commit('promises', { tenants: promise });
			return promise;
		},
		ensureAssets({ state, dispatch }) {
			if (state.promises.assets) {
				return state.promises.assets;
			}
			if (state.stations && state.systems && state.spacecrafts) {
				return new Promise(resolve => resolve());
			}
			return dispatch('refreshAssets');
		},
		refreshAssets({ state, commit }) {
			if (state.promises.assets) {
				return state.promises.assets;
			}

			const promise = new Asset()
				.list()
				.then(assets => {
					commit('stations', { stations: assets.stations });
					commit('systems', { systems: assets.systems });
					commit('spacecrafts', { spacecrafts: assets.spacecrafts });
					commit('missionProfiles', { missionProfiles: assets.mission_profiles });
				})
				.catch(ex => EventBus.emit('exception', { ex, message: 'Unable to fetch all assets', type: 'error' }));

			commit('promises', { assets: promise });
			return promise;
		},
		ensureContacts({ state, commit, dispatch }) {
			const withinLastFetchedTimespan =
				state.contactsTimespan.from >= state.lastFetchContactsTimespan.from &&
				state.contactsTimespan.to <= state.lastFetchContactsTimespan.to;

			if (state.contacts && withinLastFetchedTimespan) {
				return new Promise(resolve => resolve());
			}

			return new Promise((resolve, reject) => {
				dispatch('refreshContacts').then(() => {
					commit('promises', { contacts: null });
					resolve();
				})
					.catch(ex => {
						reject(ex);
					});
			});
		},
		refreshContacts({ state, commit }) {
			if (state.promises.contacts) {
				return state.promises.contacts;
			}

			const timespan = state.contactsTimespan;

			const promise = new Contact(timespan.from, timespan.to).all()
				.then(contacts => {
					commit('lastFetchContactsTimespan', { timespan });
					commit('contacts', { contacts });
				})
				.catch(ex => {
					EventBus.emit('exception', { ex, message: 'Unable to fetch contacts', type: 'error' });
				});
			commit('promises', { contacts: promise });

			return promise;
		},
		ensureOrganizations({ state, dispatch }) {
			if (state.promises.organizations) {
				return state.promises.organizations;
			}
			if (state.organizations) {
				return new Promise(resolve => resolve());
			}
			return dispatch('refreshOrganizations');
		},
		refreshOrganizations({ state, commit }) {
			if (state.promises.organizations) {
				return state.promises.organizations;
			}

			const promise = new Organization()
				.list()
				.then(organizations => commit('organizations', { organizations }))
				.catch(ex => EventBus.emit('exception', { ex, message: 'Unable to fetch organizations', type: 'error' }));

			commit('promises', { organizations: promise });
			return promise;
		},
		ensureMetricDefinitions({ state, commit }) {
			if (state.metricDefinitions) {
				return new Promise(resolve => resolve());
			}

			const promise = new MetricDefinition().list()
				.then(metricDefinitions => {
					commit('metricDefinitions', { metricDefinitions });
				})
				.catch(ex => {
					EventBus.emit('exception', { ex, message: 'Unable to fetch realtime metric definitions', type: 'error' });
				});
			return promise;
		},
		getAlerts({ commit }) {
			const promise = new Alert().list()
				.then(alerts => {
					commit('alerts', { alerts });
				})
				.catch(ex => {
					EventBus.emit('exception', { ex, message: 'Unable to fetch active alerts', type: 'error' });
				});
			return promise;
		},
		getNewestReleaselog({ state, commit }) {
			if (state.seenReleaselog) {
				return;
			}

			const promise = new Releaselog().list({ newest: true })
				.then(newestReleaselog => commit('newestReleaselog', { newestReleaselog }))
				.catch(ex => {
					EventBus.emit('exception', { ex, message: 'An error occured when checking for new releases', type: 'error' });
				});
			return promise;
		},
		hasSeenReleaselog({ commit }) {
			commit('seenReleaselog', { seenReleaselog: true });
		},
		addContact({ commit }, contact) {
			commit('addContact', contact);
		},
		removeContact({ commit }, contact) {
			commit('removeContact', contact);
		},
		updateContact({ commit }, contact) {
			commit('removeContact', contact);
			commit('addContact', contact);
		},
		resetStore({ commit }, options = {}) {
			commit('resetStore', options);
		},
		setTenant({ state, commit, dispatch }, tenantId) {
			if (state.tenant?.id === tenantId) {
				return new Promise(resolve => resolve());
			}

			const tenant = state.tenants.find(tenant => tenant.id === tenantId);

			commit('tenant', { tenant });
			commit('resetStore', { tenantChanged: true });

			return dispatch('refreshData');
		},
		setContactsTimespan({ commit }, timespan) {
			commit('contactsTimespan', { timespan });
			commit('defaultContactsTimespanEnabled', false);
		},
		setDefaultContactsTimespan({ commit }) {
			const timespan = {
				from: DateTime.utc().minus({ hours: 4 })
					.set({ minute: 0, second: 0, milliseconds: 0 }),
				to: DateTime.utc().plus({ hours: 25 })
					.set({ minute: 0, second: 0, milliseconds: 0 })
			};

			commit('contactsTimespan', { timespan });
			commit('defaultContactsTimespanEnabled', true);
		}
	}
});

export default store;
