import axios from 'axios';

export default class JsonSchema {
	get(id) {
		return new Promise((resolve, reject) => axios
			.get('/api/schemas/' + id)
			.then(response => resolve(response.data))
			.catch(ex => reject(ex.response))
		);
	}
}
