import { isArray, isString } from 'lodash';

import { expandRef } from '.';

export const isDateTime = (schema) => {
	return schema.type === 'string' &&
		['date-time', 'date'].includes(schema.format);
};

export const isOneOfConst = (schema) => {
	return !isString(schema.type) &&
		isArray(schema.oneOf) &&
		schema.oneOf.every((item) => {
			return Object.keys(item).includes('const');
		});
};

export const isOneOfConstArray = (schema) => {
	return schema.type === 'array' &&
		isOneOfConst(expandRef(schema, schema.items));
};

export const isEnum = (schema) => {
	return schema.type === 'string' && Array.isArray(schema.enum);
};

export const isEnumArray = (schema) => {
	return schema.type === 'array' &&
		isEnum(expandRef(schema, schema.items));
};

export const isFraction = (schema) => {
	return consistOfProperties(schema, 'nominator', 'denominator');
};

export const isMeasurement = (schema) => {
	return consistOfProperties(schema, 'unit', 'value');
};

const consistOfProperties = (schema, ...properties) => {
	if (schema.type !== 'object') {
		return false;
	}
	if (Object.keys(schema.properties ?? {}).length !== properties.length) {
		return false;
	}

	return properties.every((property) => {
		return Object.keys(schema.properties).includes(property);
	});
};
