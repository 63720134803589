import axios from 'axios';
import { DateTime, Interval } from 'luxon';

const baseURL = '/api/predictions';
export default class Prediction {
	list(spacecraft, tenant, from, to, stations, useOnDemand = false) {
		return new Promise((resolve, reject) => axios
			.post(baseURL, {
				spacecraft_uuid: spacecraft,
				tenant_uuid: tenant,
				start_time: from,
				end_time: to,
				station_uuids: stations,
				use_on_demand: useOnDemand
			})
			.then(response => {
				const predictionsWithTimeInterval = response.data.predictions.map(prediction => {
					return {
						...prediction,
						timeInterval: Interval.fromISO(`${prediction.visibility_start}/${prediction.visibility_end}`, { zone: 'utc' })
					};
				});
				resolve(
					{
						...response.data,
						ephemeris: response.data.ephemeris?.map(e => ({
							...e,
							computed_at: DateTime.fromISO(e.computed_at)
						})) ?? [],
						predictions: predictionsWithTimeInterval
					}
				);
			})
			.catch(ex => {
				console.error(ex);
				reject(ex.response);
			})
		);
	}
}
