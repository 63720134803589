// Workaround which should be replaced by the navigator API as soon as we can (migrated to https)

export const copyToClipboard = str => {
	if (window.isSecureContext) {
		navigator.clipboard.writeText(str);
		return;
	}
	const el = document.createElement('textarea');
	el.value = str;
	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
};
