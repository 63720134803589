import { isArray, isNil } from 'lodash';

import { expandDefinition } from './definition';
import { schemaBase } from './schema-base';
import { expandAllOf } from './properties';
import { expandRef } from './ref';

const isRegularProperty = (schema, property) => {
	return !isNil(schema.properties) &&
		!isNil(schema.properties[property]);
};

const isEnumProperty = (schema, property) => {
	return !isNil(schema.propertyNames) &&
		isArray(schema.propertyNames.enum) &&
		schema.propertyNames.enum.includes(property);
};

const includesAllOf = (schema) => {
	return !isNil(schema.allOf);
};

const expandEnumProperty = (schema) => {
	return expandDefinition(schema, schema.additionalProperties);
};

const expandPatternProperty = (schema, property) => { // eslint-disable-line
	const match = Object.entries(schema.patternProperties)
		.find(([expression]) => {
			return new RegExp(expression).test(property);
		});

	if (!isArray(match)) {
		return null;
	}

	return expandDefinition(schema, match[1]);
};

export const hoistProperty = (schema, properties) => {
	if (isArray(properties) && properties.length === 0) {
		return schema;
	}
	if (!isArray(properties)) {
		properties = properties.split('.');
	}

	const property = properties.shift();

	if (isRegularProperty(schema, property)) {
		return hoistProperty(
			{
				...expandRef(schema, schema.properties[property]),
				...schemaBase(schema)
			},
			properties
		);
	}

	if (isEnumProperty(schema, property)) {
		return hoistProperty(
			{
				...expandEnumProperty(schema, property),
				...schemaBase(schema)
			},
			properties
		);
	}

	if (includesAllOf(schema)) {
		return hoistProperty(
			{
				...expandAllOf(schema, property),
				...schemaBase(schema)
			},
			properties
		);
	}

	if (!isNil(schema.additionalProperties) && schema.additionalProperties !== false) {
		console.warn('Hoisting from schema.additionalProperties not implemented');
	} else {
		console.warn(`Unable to hoist '${property}' from:`, schema);
	}

	return schema;
};
