import axios from 'axios';
import { DateTime } from 'luxon';

const baseURL = '/api/ephemeris/timeline';
export default class EphemerisTimeline {
	get(spacecraftId, from, to, inlineRepresentations = ['TLE']) {
		const url = baseURL + '/history';
		return new Promise((resolve, reject) => axios
			.post(url, {
				spacecraft_uuid: spacecraftId,
				from: from.toISO(),
				to: to.toISO(),
				inline_representations: inlineRepresentations
			})
			.then(response => {
				resolve(
					{
						tenantId: response.data.tenant_uuid,
						spacecraftId: response.data.spacecraft_uuid,
						from: response.data.from,
						to: response.data.to,
						timeline: response.data.timeline.map(entry => ({
							ephemeris: {
								epoch: entry.ephemeris.epoch,
								inline: entry.ephemeris.inline,
								kind: entry.ephemeris.kind,
								origin: entry.ephemeris.origin,
								ephemerisId: entry.ephemeris.ephemeris_uuid,
								spacecraftId: response.data.spacecraft_uuid,
								lastUsableAt: DateTime.fromISO(entry.ephemeris.last_usable_at, { zone: 'utc' }),
								submittedAt: DateTime.fromISO(entry.ephemeris.submitted_at, { zone: 'utc' })
							},
							presence: entry.presence,
							validityStart: DateTime.fromISO(entry.validity_start, { zone: 'utc' }),
							validityExpire: DateTime.fromISO(entry.validity_expire, { zone: 'utc' })
						}))
					}
				);
			})
			.catch(ex => {
				console.error(ex);
				reject(ex.response);
			})
		);
	}

	remove(ephemerisId, tenantId, spacecraftId) {
		const url = baseURL + '/remove';
		return new Promise((resolve, reject) => axios
			.post(url, {
				ephemeris_uuid: ephemerisId,
				tenant_uuid: tenantId,
				spacecraft_uuid: spacecraftId
			})
			.then(() => {
				resolve({});
			})
			.catch(ex => {
				reject(ex.response);
			})
		);
	}
}
