import axios from 'axios';

export default class Issue {
	sendFeedback(message, page = null) {
		return new Promise((resolve, reject) => {
			axios
				.post('/api/issues/feedback', { message, page })
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	listFeedback() {
		return new Promise((resolve, reject) => {
			axios
				.get('/api/issues/feedback')
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}
}
