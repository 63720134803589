import axios from 'axios';
import Lodash from '@/assets/helpers/Lodash';
import { isAdmin } from '@/assets/plugins/AuthHandler';
import { DateTime } from 'luxon';

export default class Operational {
	constructor() {
		this.baseUrl = '/api/operational';
	}

	add(maintenance) {
		return this._post(this.baseUrl, maintenance);
	}

	update(maintenance) {
		const url = this.baseUrl + '/' + maintenance.id;
		return this._post(url, maintenance);
	}

	remove(id) {
		const url = this.baseUrl + '/' + id;
		return new Promise((resolve, reject) => {
			axios
				.delete(url)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	list() {
		return this._get(this.baseUrl);
	}

	addMessage(id, message) {
		const url = this.baseUrl + '/' + id + '/log';
		return this._post(url, message);
	}

	_get(url) {
		return new Promise((resolve, reject) => {
			axios
				.get(url)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	_post(url, data) {
		return new Promise((resolve, reject) => {
			axios
				.post(url, data)
				.then(response => resolve(response.data))
				.catch(ex => reject(ex.response));
		});
	}

	mapMaintenances(maintenances, systems, stations) {
		if (!maintenances || !maintenances.length) {
			return [];
		}

		// Finds name of the affected systems and stations based on their ids
		const filter = (affected, all) => {
			return affected
				.map(value => {
					const found = all.find(element => element.id === value);
					if (isAdmin()) {
						return found ? found.name : value;
					}
					return found ? found.name : false;
				})
				.filter(Boolean);
		};

		return new Lodash(maintenances)
			.cloneDeep()
			.each(m => {
				m.affected_systems = filter(m.affected_systems, systems);
				m.affected_stations = filter(m.affected_stations, stations);
				m.start_at = DateTime.fromISO(m.start_at).toUTC();
				m.stop_at = DateTime.fromISO(m.stop_at).toUTC();
			})
			.sortBy('start_at')
			.reverse()
			.value();
	}
}

/**
 * Original documentation for use of the Operational API here:
 * https://gitlab.ksat.no/IGNS/External/ksat-api-operational/blob/master/api-specs/operational.yaml
 *
 * Use it to confirm valid fields in body etc.
 */
